// translation/translations.js
const translations = {
  timesheet: {
    en: {
      timesheet: "Timesheet List",
      addtimesheet: "Add Timesheet",
      year: "Year",
      all: "All",
      month: "Month",
      date: "Date",
      projectname: "Project Name",
      responsibleuser: "Responsible User",
      totalhours: "Total Hours",
      status: "Status",
      comment: "Comment",
      action: "Action",
      project: "Project",
      back: "Back",
      name: "Name",
      jobtitle: "Job Title",
      shift: "Shift",
      workingstatus: "Working Status",
      timesheetcode: "Timesheet Code",
      budgetcode: "Budget code",
      attendancelist: "Attendance List",
      selectproject: "Select a project",
      entercomment: "Write a comment",
      working: "Working",
      day: "Day",
      night: "Night",
      notworking: "Not Working",
      areyousure: "Are you sure?",
      onceudeleteituwillnotbeabletorecoverthistimesheet:
        "once deleted, you will not be able to recover this timesheet!",
      warning: "warning",
      failedtodeletethetimesheet: "Failed to delete the timesheet",
      proofyourtimesheethasbeendelted: "Poof! Your timesheet has been deleted!",
      error: "error",
      httperrorstatus: "HTTP error! Status: ",
      therewasissuewithfetchoperation:
        "There was an issue with the fetch operation: ",
      noaccesstokenavailable: "No access token available.",
      notimesheetfound: "No timesheet details found",
      surname: "Surname",
      employee: "Employee",
      action: "Action",
      validationerror: "Validation Error",
      noemployeesfoundinattendancelist:
        "No employees found in attendance list.",
      timesheetidnotfoundintheresponse:
        "Timesheet ID not found in the response",
      failedtoaddtimesheet: "Failed to add timesheet",
      duplicateentrysimilartimesheetalreadyexists:
        "Duplicate entry: A similar timesheet already exists.",
      failedtoaddattendancedetails: "Failed to add attendance details",
      success: "success",
      timesheetandattendanceaddedsuccessfully:
        "Timesheet and attendance added successfully!",
      failedtoaddtimesheetorattendance: "Failed to add timesheet or attendance",
      paginationOf: "of",
      pagination: "Page",
      previous: "Previous Page",
      next: "Next Page",
      namesurname: "Name Surname",
      employeeeid: "Employee eID",
    },
    uz: {
      timesheet: "Vaqt Jadvallari Ro'yxati",
      addtimesheet: "Vaqt Jadvalini Qo'shish",
      year: "Yil",
      all: "Hammasi",
      month: "Oy",
      date: "Sana",
      projectname: "Loyiha Nomi",
      responsibleuser: "Mas'ul Foydalanuvchi",
      totalhours: "Umumiy Soat",
      status: "Holati",
      comment: "Izoh",
      action: "Harakat",
      project: "Loyiha",
      back: "Orqaga",
      name: "Nomi",
      jobtitle: "Lavozim Nomi",
      shift: "Smena",
      workingstatus: "ish holati",
      timesheetcode: "vaqt jadvalining kodi",
      budgetcode: "Byudjet Kodeksi",
      attendancelist: "Davomat Ro'yxati",
      selectproject: "Loyihani tanlang",
      entercomment: "Fikr kiriting",
      working: "ishlayapti",
      day: "Kun",
      night: "Kecha",
      notworking: "Ishlamayapti",
      areyousure: "Ishonchingiz komilmi?",
      onceudeleteituwillnotbeabletorecoverthistimesheet:
        "o'chirilgandan so'ng, siz ushbu jadvalni tiklay olmaysiz!",
      warning: "ogohlantirish",
      failedtodeletethetimesheet: "Vaqt jadvalini olib tashlab bo'lmadi",
      proofyourtimesheethasbeendelted:
        "Puf! Sizning vaqt jadvalingiz o'chirildi!",
      error: "Xato",
      httperrorstatus: "HTTP xato! Holati: ",
      therewasissuewithfetchoperation:
        "Fetch operatsiyasi bilan bog'liq muammo bor edi: ",
      noaccesstokenavailable: "Kirish tokeni mavjud emas.",
      notimesheetfound: "Vaqt jadvalining tafsilotlari topilmadi",
      surname: "Familiyasi",
      employee: "Xodim",
      validationerror: "Tasdiqlash Xatosi",
      noemployeesfoundinattendancelist: "davomat ro'yxatida xodimlar topilmadi",
      timesheetidnotfoundintheresponse: "Timesheet ID javob topilmadi",
      failedtoaddtimesheet: "Vaqt jadvalini qo'shib bo'lmadi",
      duplicateentrysimilartimesheetalreadyexists:
        "Ikki nusxadagi kirish: shunga o'xshash vaqt jadvali allaqachon mavjud.",
      failedtoaddattendancedetails: "Davomat tafsilotlarini qo'shib bo'lmadi",
      success: "muvaffaqiyat",
      timesheetandattendanceaddedsuccessfully:
        "Timesheet va davomat muvaffaqiyatli qo'shildi!  ",
      failedtoaddtimesheetorattendance:
        "Vaqt jadvalini yoki davomatni qo'shib bo'lmadi",
      paginationOf: "dan",
      pagination: "Sahifa",
      previous: "Oldingi Sahifa",
      next: "Keyingi Sahifa",
      namesurname: "Ism Familiyasi",
      employeeeid: "Xodim hayit",
    },
    tr: {
      timesheet: "Puantaj",
      addtimesheet: "Puantaj Ekle",
      year: "Yıl",
      all: "Tüm",
      month: "Ay",
      date: "Tarih",
      projectname: "Proje Adı",
      responsibleuser: "Sorumlu",
      totalhours: "Toplam Çalışma Saatleri",
      status: "Durum",
      comment: "Yorum",
      action: "Aksiyon",
      project: "Proje",
      back: "geri",
      name: "Adi Soyadi",
      jobtitle: "Görevi",
      shift: "Vardiya",
      workingstatus: "çalışma durumu",
      timesheetcode: "zaman çizelgesi kodu",
      budgetcode: "Bütçe Kodu",
      attendancelist: "Personel listesi",
      selectproject: "Proje seç",
      entercomment: "Yorum gir",
      working: "Çalışıyor",
      day: "Gün",
      night: "Gece",
      notworking: "Çalışmıyor",
      areyousure: "Emin misin?",
      onceudeleteituwillnotbeabletorecoverthistimesheet:
        "silindikten sonra, bu zaman çizelgesini kurtaramayacaksınız!",
      warning: "uyarı",
      failedtodeletethetimesheet: "Zaman çizelgesi silinemedi",
      proofyourtimesheethasbeendelted: "Puf! Zaman çizelgeniz silindi!",
      error: "Hata",
      httperrorstatus: "HTTP hatası! Durum: ",
      therewasissuewithfetchoperation:
        "Getirme işlemi ile ilgili bir sorun vardı: ",
      noaccesstokenavailable: "Erişim belirteci yok.",
      notimesheetfound: "Zaman çizelgesi detayı bulunamadı",
      surname: "Soyadı",
      employee: "Personel",
      validationerror: "Doğrulama Hatası",
      noemployeesfoundinattendancelist:
        "Personel listesinde Personel bulunamadı",
      timesheetidnotfoundintheresponse:
        "Yanıtta zaman çizelgesi kimliği bulunamadı",
      failedtoaddtimesheet: "Zaman çizelgesi eklenemedi",
      duplicateentrysimilartimesheetalreadyexists:
        "Yinelenen giriş: Benzer bir zaman çizelgesi zaten var.",
      failedtoaddattendancedetails: "Katılım ayrıntıları eklenemedi",
      success: "başarı",
      timesheetandattendanceaddedsuccessfully:
        "Zaman çizelgesi ve katılım başarıyla eklendi!",
      failedtoaddtimesheetorattendance:
        "Zaman çizelgesi veya katılım eklenemedi",
      paginationOf: "dan",
      pagination: "Sayfa",
      previous: "Önceki sayfa",
      next: "Sonraki Sayfa",
      namesurname: "Adı Soyadı",
      employeeeid: "Personel Bayram",
    },
    ru: {
      timesheet: "Табель учета рабочего времени",
      addtimesheet: "Добавить табель",
      year: "Год",
      all: "Все",
      month: "Месяц",
      date: "Дата",
      projectname: "Проект",
      responsibleuser: "Ответственный",
      totalhours: "Общее кол-во часов",
      status: "Статус",
      comment: "Комментарий",
      action: "Действие",
      project: "Проект",
      name: "Ф.И.О",
      jobtitle: "должность",
      shift: "Смена",
      workingstatus: "рабочее состояние",
      timesheetcode: "код расписания",
      budgetcode: "Бюджетный код",
      attendancelist: "Список сотрудников",
      back: "Назад",
      selectproject: "Выбрать проект",
      entercomment: "Введите комментарий",
      working: "Работает",
      day: "День",
      night: "Kecha",
      notworking: "Не работает",
      areyousure: "Вы уверены?",
      onceudeleteituwillnotbeabletorecoverthistimesheet:
        "после удаления вы не сможете восстановить это расписание!",
      warning: "предупреждение",
      failedtodeletethetimesheet: "Не удалось удалить расписание",
      proofyourtimesheethasbeendelted: "Пуф! Ваше расписание было удалено!",
      error: "Ошибка",
      httperrorstatus: "Ошибка HTTP! Статус: ",
      therewasissuewithfetchoperation:
        "Возникла проблема с операцией выборки: ",
      noaccesstokenavailable: "Токен доступа недоступен.",
      notimesheetfound: "Информация о расписании не найдена",
      surname: "Фамилия",
      employee: "Сотрудник",
      validationerror: "Ошибка проверки",
      noemployeesfoundinattendancelist:
        "в списке участников не найдено ни одного сотрудника",
      timesheetidnotfoundintheresponse:
        "Идентификатор расписания не найден в ответе",
      failedtoaddtimesheet: "Не удалось добавить расписание",
      duplicateentrysimilartimesheetalreadyexists:
        "Повторяющаяся запись: Похожее расписание уже существует.",
      failedtoaddattendancedetails:
        "Не удалось добавить сведения о посещаемости",
      success: "успех",
      timesheetandattendanceaddedsuccessfully:
        "Расписание и посещаемость добавлены успешно!",
      failedtoaddtimesheetorattendance:
        "Не удалось добавить расписание или посещаемость",
      paginationOf: "из",
      pagination: "Страница",
      previous: "Предыдущий",
      next: "Следующий",
      namesurname: "Имя Фамилия",
      employeeeid: "ИД сотрудника",
    },
  },
  payroll: {
    en: {
      pleasechooseatleastoneoption: "Please choose at least one option",
      error: "Error",
      failedtofetchpayrolldata: "Failed to fetch payroll data",
      failedtoaddpayroll: "Failed to add payroll",
      payrollidnotfoundintheresponse: "Payroll ID not found in the response",
      failedtosendpayrolldetailsrolledbackchanges:
        "Failed to send payroll details, rolled back changes",
      success: "success",
      payrolladdedanddetailssentsuccessfully:
        "Payroll added and details sent successfully",
      failedtoaddpayrollandsenddetails:
        "Failed to add payroll and send details",
      novalidpayrolldataavailabletosend:
        "No valid payroll data available to send",
      payroll: "Payroll",
      employee: "Employee",
      addpayroll: "Add Payroll",
      project: "Project",
      year: "Year",
      selectproject: "Select a project",
      month: "Month",
      back: "Back",
      date: "Date",
      fullname: "Full Name",
      jobtitle: "Job Title",
      costcenter: "Cost Center",
      workschedule: "Work Schedule",
      paymenttype: "Payment Type",
      currency: "Currency",
      regularhoursdays: "Regular Hours/Days",
      overtimehoursdays: "Overtime Hours/Days",
      totalhoursdaysworked: "Total Hours/Days Worked",
      calculatedsalary: "Calculated Salary",
      overtimesalary: "Overtime Salary",
      overtimemultiplier: "Overtime Multiplier",
      totalsalary: "Total Salary",
      days: "days",
      hours: "hours",
      selectmonth: "Select a month",
      httperrorstatus: "HTTP error! Status: ",
      therewasissuewithfetchoperation:
        "There was an issue with the fetch operation: ",
      areyousure: "Are you sure?",
      onceudeleteituwillnotbeabletorecoverthispayroll:
        "once deleted, you will not be able to recover this payroll!",
      warning: "warning",
      failedtodeletethepayroll: "Failed to delete the payroll",
      proofyourpayrollhasbeendeleted: "Poof! Your payroll has been deleted!",
      failedtodeletepayroll: "Failed to delete payroll",
      paginationOf: "of",
      previous: "Previous Page",
      next: "Next Page",
      pagination: "Page",
      payrolllist: "Payroll List",
      addpayrolllist: "Add Payroll",
      all: "All",
      projectname: "Project",
      responsibleuser: "Responsible",
      totalcost: "Total Amount",
      status: "Status",
      action: "Action",
      deselectall: "Deselect All",
      select_all: "Select All",
      filterbyfullname: "Filter by Full Name",
      filterbyjobtitle: "Filter by Job Title",
      select: "select",
      notimesheetfound: "No timesheet details found",
      filterbyname: "Filter by Name",
      enterfullname: "Enter full name",
      filterbyproject: "Filter by Project",
      enterprojectname: "Enter project name",
      filterbyjobtitle: "Filter by Job Title",
      enterjobtitle: "Enter job title",
      apply: "Apply",
      download: "Download",
      employeename: "Employee Name",
      period: "Period",
      employeereport: "Employee Report",
    },
    uz: {
      pleasechooseatleastoneoption: "Iltimos, kamida bitta variantni tanlang",
      error: "Xato",
      failedtofetchpayrolldata: "Bordro ma'lumotlarini olib bo'lmadi",
      failedtoaddpayroll: "Bordro qo'shib bo'lmadi",
      payrollidnotfoundintheresponse: "Bordro ID javob topilmadi",
      failedtosendpayrolldetailsrolledbackchanges:
        "Bordro tafsilotlarini yuborib bo'lmadi, orqaga qaytdi",
      success: "muvaffaqiyat",
      payrolladdedanddetailssentsuccessfully:
        "Bordro qo'shildi va tafsilotlar muvaffaqiyatli yuborildi",
      failedtoaddpayrollandsenddetails:
        "Bordro qo'shib bo'lmadi va ma'lumotlarni jo'natib bo'lmadi",
      novalidpayrolldataavailabletosend:
        "Yuborish uchun haqiqiy ish haqi ma'lumotlari mavjud emas",
      payroll: "bordro",
      employee: "xodim",
      addpayroll: "Bordro Qo'shish",
      project: "Loyiha",
      year: "Yil",
      selectproject: "Loyihani tanlang",
      month: "Oy",
      back: "Orqaga",
      date: "Sana",
      fullname: "To'liq Ism",
      jobtitle: "Lavozim Nomi",
      costcenter: "Xarajatlar Markazi",
      workschedule: "Ish Jadvali",
      paymenttype: "To'lov Turi",
      currency: "Valyuta",
      regularhoursdays: "Muntazam Soat / Kun",
      overtimehoursdays: "Qo'shimcha Ish Soatlari / Kunlar",
      totalhoursdaysworked: "Jami Ishlagan Soatlar / Kunlar",
      calculatedsalary: "Hisoblangan Ish Haqi",
      overtimesalary: "Qo'shimcha Ish Haqi",
      overtimemultiplier: "Qo'shimcha Vaqt Multiplikatori",
      totalsalary: "Umumiy Ish Haqi",
      days: "kunlar",
      hours: "soat",
      selectmonth: "Bir oyni tanlang",
      httperrorstatus: "HTTP xato! Holati: ",
      therewasissuewithfetchoperation:
        "Fetch operatsiyasi bilan bog'liq muammo bor edi: ",
      areyousure: "Ishonchingiz komilmi?",
      onceudeleteituwillnotbeabletorecoverthispayroll:
        "o'chirilgandan so'ng, siz ushbu ish haqini tiklay olmaysiz!",
      warning: "ogohlantirish",
      failedtodeletethepayroll: "Bordro o'chirilmadi",
      proofyourpayrollhasbeendeleted: "Puf! Sizning bordro o'chirildi!",
      failedtodeletepayroll: "Bordro o'chirilmadi",
      paginationOf: "dan",
      previous: "Oldingi Sahifa",
      next: "Keyingi Sahifa",
      pagination: "Sahifa",
      payrolllist: "Bordro Ro'yxati",
      addpayrolllist: "Bordro Qo'shish",
      all: "Hammasi",
      projectname: "Loyiha Nomi",
      responsibleuser: "Mas'ul Foydalanuvchi",
      totalcost: "Umumiy Qiymati",
      status: "Holati",
      action: "Harakat",
      deselectall: "olib tashlash",
      select_all: "Hammasini Tanlash",
      filterbyfullname: "To'liq nomi bilan filtrlash",
      filterbyjobtitle: "Ish nomi bo'yicha filtrlash",
      select: "tanlash",
      notimesheetfound: "Vaqt jadvalining tafsilotlari topilmadi",
      filterbyname: "nomi bo'yicha filtrlash",
      enterfullname: "To'liq ismni kiriting",
      filterbyproject: "Loyiha bo'yicha filtrlash",
      enterprojectname: "Loyiha nomini kiriting",
      filterbyjobtitle: "Ish nomi bo'yicha filtrlash",
      enterjobtitle: "Ish nomini kiriting",
      apply: "Qo'llash",
      download: "Yuklash",
      employeename: "Xodimning Ismi",
      period: "Davr",
      employeereport: "Xodimlarning Hisoboti",
    },
    tr: {
      pleasechooseatleastoneoption: "Lütfen en az bir seçenek belirleyin",
      error: "Hata",
      failedtofetchpayrolldata: "Bordro verileri alınamadı",
      failedtoaddpayroll: "Bordro eklenemedi",
      payrollidnotfoundintheresponse: "Yanıtta bordro kimliği bulunamadı",
      failedtosendpayrolldetailsrolledbackchanges:
        "Bordro ayrıntıları gönderilemedi, değişiklikleri geri aldı",
      success: "başarı",
      payrolladdedanddetailssentsuccessfully:
        "Bordro eklendi ve ayrıntılar başarıyla gönderildi",
      failedtoaddpayrollandsenddetails:
        "Bordro eklenemedi ve ayrıntılar gönderilemedi",
      novalidpayrolldataavailabletosend:
        "Gönderilebilecek geçerli bordro verisi yok",
      payroll: "maaş bordrosu",
      employee: "Personel",
      addpayroll: "Bordro Ekle",
      project: "Proje",
      year: "Yıl",
      selectproject: "Proje seç",
      month: "Ay",
      back: "Geri",
      date: "Tarih",
      fullname: "Adi Soyadi",
      jobtitle: "Görevi",
      costcenter: "Masraf merkezi",
      workschedule: "Çalışma grafiği",
      paymenttype: "Ödeme Şekli",
      currency: "Para birimi",
      regularhoursdays: "Normal Saatler / Günler",
      overtimehoursdays: "Fazla Mesai Saatleri / Günleri",
      totalhoursdaysworked: "Çalışılan Toplam Saat / Gün",
      calculatedsalary: "Hesaplanan Maaş",
      overtimesalary: "Fazla Mesai Maaşı",
      overtimemultiplier: "Fazla Mesai Katsayısı",
      totalsalary: "Toplam Maaş",
      days: "günler",
      hours: "saatler",
      selectmonth: "Bir ay seç",
      httperrorstatus: "HTTP hatası! Durum: ",
      therewasissuewithfetchoperation:
        "Getirme işlemi ile ilgili bir sorun vardı: ",
      areyousure: "Emin misin?",
      onceudeleteituwillnotbeabletorecoverthispayroll:
        "silindikten sonra, bu bordroyu kurtaramayacaksınız!",
      warning: "uyarı",
      failedtodeletethepayroll: "Bordro silinemedi",
      proofyourpayrollhasbeendeleted: "Puf! Maaş bordronuz silindi!",
      failedtodeletepayroll: "Bordro silinemedi",
      paginationOf: "dan",
      previous: "Önceki sayfa",
      next: "Sonraki Sayfa",
      pagination: "Sayfa",
      payrolllist: "Bordro Listesi",
      addpayrolllist: "Bordro Ekle",
      all: "Hepsi",
      projectname: "Proje Adı",
      responsibleuser: "Sorumlu",
      totalcost: "Toplam Tutar",
      status: "Durum",
      action: "Aksiyon",
      deselectall: "seçimi kaldır",
      select_all: "Tümünü Seç",
      filterbyfullname: "Adı Soyadına göre filtre",
      filterbyjobtitle: "Göreve göre filtre",
      select: "seç",
      notimesheetfound: "Puantaj detayı bulunamadı",
      filterbyname: "Adı Soyadına göre filtre",
      enterfullname: "Tam adı girin",
      filterbyproject: "Projeye Göre Filtrele",
      enterprojectname: "Proje adını girin",
      filterbyjobtitle: "Göreve göre filtre",
      enterjobtitle: "İş unvanını girin",
      apply: "Uygulamak",
      download: "İndirmek",
      employeename: "Çalışan Adı",
      period: "Dönem",
      employeereport: "Çalışan Raporu",
    },
    ru: {
      pleasechooseatleastoneoption: "Пожалуйста, выберите хотя бы один вариант",
      error: "Ошибка",
      failedtofetchpayrolldata: "Не удалось получить данные о заработной плате",
      failedtoaddpayroll: "Не удалось добавить зарплатную ведомость",
      payrollidnotfoundintheresponse:
        "Платежный идентификатор не найден в ответе",
      failedtosendpayrolldetailsrolledbackchanges:
        "Не удалось отправить данные о заработной плате, изменения были отменены",
      success: "успех",
      payrolladdedanddetailssentsuccessfully: "Зарплатная ведомость добавлена",
      failedtoaddpayrollandsenddetails:
        "Не удалось добавить зарплатную ведомость",
      novalidpayrolldataavailabletosend:
        "Нет доступных данных для зарплатной ведомости",
      payroll: "Зарплатная ведомость",
      employee: "сотрудник",
      addpayroll: "Добавить ведомость",
      project: "Проект",
      year: "Год",
      selectproject: "Выбрать проект",
      month: "Месяц",
      back: "Назад",
      date: "Дата",
      fullname: "Ф.И.О",
      jobtitle: "должность",
      costcenter: "Центр затрат",
      workschedule: "График работы",
      paymenttype: "Тип платежа",
      currency: "Валюта",
      regularhoursdays: "Обычные часы /Дни",
      overtimehoursdays: "Сверхурочная работа В часах/днях",
      totalhoursdaysworked: "Общее количество отработанных часов/Дней",
      calculatedsalary: "Зарплата",
      overtimesalary: "Зарплата за сверхурочную работу",
      overtimemultiplier: "Мультипликатор сверхурочных",
      totalsalary: "Общая заработная плата",
      days: "дни",
      hours: "часы",
      selectmonth: "Выбрать месяц",
      httperrorstatus: "Ошибка HTTP! Статус: ",
      therewasissuewithfetchoperation:
        "Возникла проблема с операцией выборки: ",
      areyousure: "Вы уверены?",
      onceudeleteituwillnotbeabletorecoverthispayroll:
        "после удаления вы не сможете восстановить эту платежную ведомость!",
      warning: "предупреждение",
      failedtodeletethepayroll: "Не удалось удалить платежную ведомость",
      proofyourpayrollhasbeendeleted:
        "Пуф! Ваша зарплатная ведомость была удалена!",
      failedtodeletepayroll: "Не удалось удалить платежную ведомость",
      paginationOf: "из",
      previous: "Предыдущий",
      next: "Следующий",
      pagination: "Страница",
      payrolllist: "Зарплатная ведомость",
      addpayrolllist: "Добавить зарплатную ведомость",
      all: "Все",
      projectname: "Проект",
      responsibleuser: "Ответственный",
      totalcost: "Сумма",
      status: "Статус",
      action: "Действие",
      deselectall: "Отменить",
      select_all: "Выбрать всех",
      filterbyfullname: "Фильтровать по имени",
      filterbyjobtitle: "Фильтровать по должности",
      select: "выбрать",
      notimesheetfound: "Информация о расписании не найдена",
      filterbyname: "фильтровать по названию",
      enterfullname: "Введите полное имя",
      filterbyproject: "Фильтровать по проектам",
      enterprojectname: "Введите название проекта",
      filterbyjobtitle: "Фильтровать по названию должности",
      enterjobtitle: "Введите название должности",
      apply: "Сохранить",
      download: "Скачать",
      employeename: "Имя сотрудника",
      period: "Период",
      employeereport: "Отчет о сотруднике",
    },
  },
  registration: {
    en: {
      welcometoplain2do: "Welcome To Plain2do",
      herodescription:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,",
      signinbyenteringinformationbelow: "Sign in by entering information below",
      remembermypreferemce: "Remember my preference",
      forgotpassword: "Forgot Password",
      donothaveaccount: "Don't have an account?",
      signup: "Sign up",
      signin: "Sign in",
      username: "username",
      typeyourusernameaddress: "Type Your username Address",
      password: "Password",
      typeyourpassword: "Type Your Password",
      getnewpasswordbyenteringyouremail:
        "Get new password by entering your email",
      email: "email",
      typeyouremailaddress: "Type Your Email Address",
      send: "Send",
      codeverification: "Code Verification",
      enterthesentcodefromyouremail: "Enter the sent code from your email",
      code: "code",
      verify: "Verify",
      previous: "Previous Page",
      enterthecode: "Enter the code",
      incorrectcode: "Incorrect code",
      successfulverification: "Successful Verification",
      success: "success",
      usernotfound: "User not found",
      error: "error",
      checkyouremailplease: "Check Your Email, please!",
      thepassworddoesnotmatch: "The passwords do not match",
      newpassword: "New Password",
      createanewpassword: "Create a new password",
      updatepassword: "Update password",
      typeyournewpassword: "Type Your New Password",
      confirmpassword: "Confirm password",
      thepasswordmustmatch: "The password must match!",
      submit: "Submit",
      nolessthan8words: "No less than 8 words",
      morethanoneuppercaseletter: "More than one Uppercase letter",
      morethanonelowercaseletter: "More than one Lowercase letter",
      morethanonedigit: "More than one digit",
      signupbyenteringinformationbelow: "Sign up by entering information below",
      haveanaccount: "have an account?",
      theuserexists: "The user exists",
      usernamerequired: "Username is Required",
      passwordisrequired: "Password is Required",
      thereareonlyfewstepslefttogetstarted:
        "There are only few steps left to get started",
      thedatahasbeensenttoyouremailpleasecheckyouremail:
        "The data has been sent to your email. Please check your email!",
      theemailisalreadyinuse: "The email is already in use",
      enteryourfirstname: "Enter your first name",
      enteryourlastname: "Enter your lastname",
      next: "Next",
      enteryourpassword: "Enter your password",
      companyINN: "Company INN",
      companyKPP: "Company KPP",
      yourcompanysize: "Your company size (number of employees)",
      onelowercaseletter: "One lowercase letter",
      oneuppercaseletter: "One uppercase letter",
      onedigit: "One digit",
      atleast8symbols: "At least 8 symbols",
      back: "Back",
      didnotreceiveviaemail: "Did not receive via email?",
      resendemail: "Resend email",
      thepasswordhasbeenreset: "The password has been reset",
      loading: "Loading",
    },
    tr: {
      welcometoplain2do: "Plain2do'ya Hoş Geldiniz",
      herodescription:
        "Bir okuyucunun, düzenine bakarken bir sayfanın okunabilir içeriğinden rahatsız olacağı köklü bir gerçektir. Lorem Ipsum'u kullanmanın amacı, az çok normal bir harf dağılımına sahip olmasıdır,",
      signinbyenteringinformationbelow: "Aşağıya bilgi girerek oturum açın",
      remembermypreferemce: "Tercihimi hatırla",
      forgotpassword: "Şifremi Unuttum",
      donothaveaccount: "Akauntingiz yok mu?",
      signup: "Giriş yap",
      signin: "kayıt ol",
      username: "Kullanıcı adı",
      typeyourusernameaddress: "Kullanıcı adı Adresinizi Yazın",
      password: "Şifre",
      typeyourpassword: "Parolanızı Yazın",
      getnewpasswordbyenteringyouremail: "E-postanızı girerek yeni şifre alın",
      email: "E-posta",
      typeyouremailaddress: "E-posta Adresinizi Yazın",
      send: "Göndermek",
      codeverification: "Kod Doğrulama",
      enterthesentcodefromyouremail: "E-postanızdan gönderilen kodu girin",
      code: "kod",
      verify: "Doğrulamak",
      previous: "Oldingi Sahifa",
      enterthecode: "Kodu girin",
      incorrectcode: "Yanlış kod",
      successfulverification: "Başarılı Doğrulama",
      success: "başarı",
      usernotfound: "Kullanıcı bulunamadı",
      error: "Hata",
      checkyouremailplease: "E-postanızı kontrol edin, lütfen!",
      thepassworddoesnotmatch: "Şifreler eşleşmiyor",
      newpassword: "Yeni Şifre",
      createanewpassword: "Yeni bir şifre oluştur",
      updatepassword: "Şifreyi güncelle",
      typeyournewpassword: "Yeni Parolanızı Yazın",
      confirmpassword: "Şifreyi onayla",
      thepasswordmustmatch: "Şifre eşleşmelidir!",
      submit: "Göndermek",
      nolessthan8words: "En az 8 kelime",
      morethanoneuppercaseletter: "Birden fazla Büyük harf",
      morethanonelowercaseletter: "Birden fazla Küçük harf",
      morethanonedigit: "Birden fazla basamak",
      signupbyenteringinformationbelow: "Aşağıya bilgi girerek kaydolun",
      haveanaccount: "akountingiz var mı?",
      theuserexists: "Kullanıcı var",
      usernamerequired: "Kullanıcı adı Gereklidir",
      passwordisrequired: "Şifre Gerekli",
      thereareonlyfewstepslefttogetstarted:
        "Başlamak için sadece birkaç adım kaldı",
      thedatahasbeensenttoyouremailpleasecheckyouremail:
        "Veriler e-postanıza gönderildi. Lütfen e-postanızı kontrol edin!",
      theemailisalreadyinuse: "E-posta zaten kullanılıyor",
      enteryourfirstname: "Adınızı girin",
      enteryourlastname: "Soyadını girin",
      next: "Sonraki",
      enteryourpassword: "Şifreyi girin",
      companyINN: "Şirket INN",
      companyKPP: "Şirket KPP",
      yourcompanysize: "Şirket büyüklüğünüz (Personel sayısı)",
      onelowercaseletter: "Bir küçük harf",
      oneuppercaseletter: "Bir büyük harf",
      onedigit: "Bir basamak",
      atleast8symbols: "En az 8 sembol",
      back: "Geri",
      didnotreceiveviaemail: "E-posta yoluyla almadınız mı?",
      resendemail: "E-postayı yeniden gönder",
      thepasswordhasbeenreset: "Parola değiştirildi",
      loading: "Yükleniyor",
    },
    uz: {
      welcometoplain2do: "Plain2do ga Xush Kelibsiz",
      herodescription:
        "O'quvchi uning tartibini ko'rib chiqayotganda sahifaning o'qiladigan mazmuni bilan chalg'itishi uzoq vaqtdan beri aniqlangan haqiqatdir. Lorem Ipsum-dan foydalanish nuqtasi shundaki, u harflarning ko'proq yoki kamroq normal taqsimlanishiga ega,",
      signinbyenteringinformationbelow:
        "Quyidagi ma'lumotlarni kiritish orqali tizimga kiring",
      remembermypreferemce: "Mening afzalligimni eslab qolish",
      forgotpassword: "Parolni Unutdingizmi",
      donothaveaccount: "Akauntingiz yo'qmi?",
      signup: "ro'yxatdan o'tish",
      signin: "kirish",
      username: "foydalanuvchi nomi",
      typeyourusernameaddress: "Foydalanuvchi nomingiz manzilini kiriting",
      password: "Maxfiy so'z",
      typeyourpassword: "Parolingizni Kiriting",
      getnewpasswordbyenteringyouremail:
        "Elektron pochtangizni kiritib, yangi parolni oling",
      email: "elektron pochta",
      typeyouremailaddress: "Elektron Pochta Manzilingizni Kiriting",
      send: "Yuborish",
      codeverification: "Kodni Tekshirish",
      enterthesentcodefromyouremail:
        "Elektron pochtangizdan yuborilgan kodni kiriting",
      code: "kod",
      verify: "Tekshirish",
      previous: "Önceki sayfa",
      enterthecode: "Kodni kiriting",
      incorrectcode: "Noto'g'ri kod",
      successfulverification: "Muvaffaqiyatli Tekshirish",
      success: "muvaffaqiyat",
      usernotfound: "Foydalanuvchi topilmadi",
      error: "Xato",
      checkyouremailplease: "Elektron pochtangizni tekshiring, iltimos!",
      thepassworddoesnotmatch: "Parollar mos kelmaydi",
      newpassword: "Yangi Parol",
      createanewpassword: "Yangi parol yarating",
      updatepassword: "Parolni yangilang",
      typeyournewpassword: "Yangi Parolni Kiriting",
      confirmpassword: "Parolni tasdiqlang",
      thepasswordmustmatch: "Parol mos kelishi kerak!",
      submit: "Sunmak",
      nolessthan8words: "8 so'zdan kam emas",
      morethanoneuppercaseletter: "Bir nechta katta harf",
      morethanonelowercaseletter: "Bir nechta kichik harf",
      morethanonedigit: "Bir nechta raqam",
      signupbyenteringinformationbelow:
        "Quyidagi ma'lumotlarni kiritish orqali ro'yxatdan o'ting",
      haveanaccount: "akauntingiz bormi?",
      theuserexists: "Foydalanuvchi mavjud",
      usernamerequired: "Foydalanuvchi nomi talab qilinadi",
      passwordisrequired: "Parol talab qilinadi",
      loading: "Yuklanmoqda",
    },
    ru: {
      welcometoplain2do: "Добро пожаловать в Plain2do",
      herodescription:
        "Давно известно, что читатель будет отвлекаться на читаемое содержимое страницы, когда будет смотреть на ее макет. Смысл использования Lorem Ipsum в том, что он имеет более или менее нормальное распределение букв,",
      signinbyenteringinformationbelow:
        "Войдите в систему, введя информацию ниже",
      remembermypreferemce: "Запомнить о моих предпочтениях",
      forgotpassword: "Забыли пароль",
      donothaveaccount: "У вас нет учетной записи?",
      signup: "зарегистрироваться",
      signin: "Войти в систему",
      username: "имя пользователя",
      typeyourusernameaddress: "Введите свой адрес пользователя",
      password: "Пароль",
      typeyourpassword: "Введите Свой пароль",
      getnewpasswordbyenteringyouremail:
        "Получите новый пароль, введя свой адрес электронной почты",
      email: "Электронная почта",
      typeyouremailaddress: "Введите свой адрес электронной почты",
      send: "Отправить",
      codeverification: "Проверка кода",
      enterthesentcodefromyouremail:
        "Введите код, отправленный с вашего электронного адреса",
      code: "Код",
      verify: "Проверить",
      previous: "Предыдущий",
      enterthecode: "Введите код",
      incorrectcode: "Неверный код",
      successfulverification: "Успешная проверка",
      success: "успех",
      usernotfound: "Пользователь не найден",
      error: "Ошибка",
      checkyouremailplease: "Пожалуйста, проверьте свою электронную почту!",
      thepassworddoesnotmatch: "Пароли не совпадают",
      newpassword: "Новый пароль",
      createanewpassword: "Создайте новый пароль",
      updatepassword: "Обновить пароль",
      typeyournewpassword: "Введите Свой Новый Пароль",
      confirmpassword: "Подтвердите пароль",
      thepasswordmustmatch: "Пароль должен совпадать!",
      submit: "Представить",
      nolessthan8words: "Не менее 8 слов",
      morethanoneuppercaseletter: "Более одной заглавной буквы",
      morethanonelowercaseletter: "Более одной строчной буквы",
      morethanonedigit: "Более одной цифры",
      signupbyenteringinformationbelow:
        "Зарегистрируйтесь, введя информацию ниже",
      haveanaccount: "у вас есть аккаунт?",
      theuserexists: "Пользователь существует",
      usernamerequired: "Требуется имя пользователя",
      passwordisrequired: "Требуется ввести пароль",
      thereareonlyfewstepslefttogetstarted:
        "Осталось всего несколько шагов, чтобы начать",
      thedatahasbeensenttoyouremailpleasecheckyouremail:
        "Данные были отправлены на ваш электронный адрес. Пожалуйста, проверьте свой электронный адрес!",
      theemailisalreadyinuse: "Это электронное письмо уже используется",
      enteryourfirstname: "Введите имя",
      enteryourlastname: "Введите фамилию",
      next: "Следующий",
      enteryourpassword: "Введите пароль",
      companyINN: "ИНН Компании",
      companyKPP: "КПП Компании",
      yourcompanysize: "Размер вашей компании (количество сотрудников)",
      onelowercaseletter: "Одна строчная буква",
      oneuppercaseletter: "Одна заглавная буква",
      onedigit: "Одна цифра",
      atleast8symbols: "Не менее 8 символов",
      back: "Назад",
      didnotreceiveviaemail: "Не получили по электронной почте?",
      resendemail: "Повторная отправка электронного письма",
      thepasswordhasbeenreset: "Пароль был изменен",
      loading: "Загрузка",
    },
  },
  uz: {
    searchPlaceholder: "Bu yerda qidiring...",
    dashboard: "Boshqaruv paneli",
    generateReport: "Hisobot yaratish",
    projects: "Loyihalar",
    listProjects: "Loyihalarni ro'yxati",
    budgets: "Budjetlar",
    ganttChart: "Gantt Diagrammasi",
    laborDemand: "Mehnat Talabi Ro'yxati",
    myTasks: "Mening Vazifalarim",
    kanbanBoard: "Kanban Doskasi",
    pendingTasks: "Kutilayotgan Vazifalar",
    employee: "Xodim",
    employeeGeneralList: "Xodimlarning Umumiy Ro'yxati",
    employeeCard: "Xodim Kartasi",
    personalInformation: "Shaxsiy Ma'lumotlar",
    employeeDocuments: "Xodim Hujjatlari",
    certificates: "Sertifikatlar",
    costCentreTransferHistory: "Narx Markazi O'tkazma Tarixi",
    timesheet: "Ish Vaqti Hisobi",
    payroll: "Ish Haqi",
    dashboards: "Boshqaruv Panellari",
    statistics: "Statistika",
    analyticalReports: "Analitik Hisobotlar",
    projectDatatables: "Loyiha Ma'lumotlari",
    downloadProjects: "Loyihani Yuklab Olish",
    addProject: "Loyiha Qo'shish",
    projectsPerPage: "Sahifasiga Loyihalar",
    projectName: "Loyiha Nomi",
    address: "Manzil",
    startDate: "Boshlanish Sanasi",
    endDate: "Tugash Sanasi",
    action: "Harakat",
    budgetData: "Budjet Ma'lumotlari",
    downloadBudgets: "Budjetlarni Yuklab Olish",
    addBudget: "Budjet Qo'shish",
    budgetsPerPage: "Sahifasiga Budjetlar",
    budgetVersion: "Budjet Versiyasi",
    budgetCode: "Budjet Kodeksi",
    next: "Keyingi Sahifa",
    previous: "Oldingi Sahifa",
    salary: "Ish Haqi",
    primaveraCode: "Primavera Kodeksi",
    pagination: "Sahifa",
    paginationOf: "dan",
    discipline: "Intizom",
    jobTitle: "Lavozim",
    employeeClass: "Xodim Sinfi",
    employeeLevel: "Xodim Darajasi",
    documentType: "Hujjat Turi",
    currency: "Valyuta",
    unitOfMeasure: "O'lchov Birligi",
    employeeQuantity: "Xodim Soni",
    employeeSalary: "Xodim Ish Haqi",
    dayDifference: "Kun Farqi",
    monthDifference: "Oy Farqi",
    yearDifference: "Yil Farqi",
    taxes: "Soliqlar",
    legalExpenses: "Yuridik Xarajatlar",
    actions: "Harakatlar",
    select_all: "Hammasini Tanlash",
    deselect_all: "Hammasini Tanlashni Bekor Qilish",
    all: "Hammasi",
    active: "Faol",
    inactive: "Nofaol",
    personal_number: "Shaxsiy Raqam",
    surname: "Familiya",
    first_name: "Ism",
    position: "Lavozim",
    mobile_number: "Mobil Raqam",
    current_status: "Holat",
    employeePerPage: "Sahifasiga Xodimlar",
    addEmployee: "Xodim Qo'shish",
    downloadEmployee: "Xodimni Yuklab Olish",
    employeesList: "Xodimlar Ro'yxati",
    employees: "Xodimlar",
    latest: "Oxirgi",
    archive: "Arxiv",
    passportInformation: "Pasport Ma'lumotlari",
    noPassportInformation: "Pasport Ma'lumotlari Yo'q",
    passport: "Pasport",
    countryCode: "Mamlakat Kodeksi",
    passportNumber: "Pasport Raqami",
    givenNames: "Berilgan Ismlar",
    nationality: "Millati",
    birthDate: "Tug'ilgan Sana",
    gender: "Jinsi",
    dateOfExpiry: "Muddati Tugash Sanasi",
    dateOfIssue: "Berilgan Sana",
    passportPhotoAlt: "Pasport Surat",
    noData: "Ko'rsatish Uchun Hech Narsa Yo'q",
    visaInformation: "Visa Ma'lumotlari",
    noVisaInformation: "Visa Ma'lumotlari Yo'q",
    visa: "Visa",
    visaNumber: "Visa Raqami",
    nameSurname: "Ism Familiya",
    issueDate: "Berilgan Sana",
    fromDate: "Boshlanish Sanasi",
    validityDate: "Amal Qilish Sanasi",
    issuedBy: "Beruvchi",
    durationOfStay: "Yashash Muddatlari",
    visaPhotoAlt: "Visa Surat",
    patentInformation: "Patent Ma'lumotlari",
    noPatentInformation: "Patent Ma'lumotlari Yo'q",
    patent: "Patent",
    registerSerialNumber: "Ro'yxatga Olish Seriya Raqami",
    patentPhotoAlt: "Patent Surat",
    innInformation: "INN Ma'lumotlari",
    noInnInformation: "INN Ma'lumotlari Yo'q",
    inn: "INN",
    documentNumber: "Hujjat Raqami",
    employeeNumber: "Xodim Raqami",
    tinNumber: "STIR Raqami",
    registrationInformation: "Ro'yxatdan O'tish Ma'lumotlari",
    noRegistrationInformation: "Ro'yxatdan O'tish Ma'lumotlari Yo'q",
    registration: "Ro'yxatdan O'tish",
    registrationAddress: "Ro'yxatga Olish Manzili",
    registrationInDormitory: "Yotoqxonada Ro'yxatga Olish",
    dormitory: "Yotoqxona",
    medicalInformation: "Tibbiy Ma'lumotlar",
    noMedicalInformation: "Tibbiy Ma'lumotlar Yo'q",
    medicalCheckup: "Tibbiy Ko'rik",
    documentSerie: "Hujjat Seriyasi",
    migrationInformation: "Migratsiya Ma'lumotlari",
    noMigrationInformation: "Migratsiya Ma'lumotlari Yo'q",
    migrationCard: "Migratsiya Kartasi",
    patents: "Patentlar",
    certificationsInformation: "Sertifikatlar Ma'lumotlari",
    noCertificationsInformation: "Sertifikatlar Ma'lumotlari Yo'q",
    certifications: "Sertifikatlar",
    certificationName: "Sertifikat Nomi",
    residencePermitInformation: "Doimiy Yashash Ruxsatnomasi Ma'lumotlari",
    noResidencePermitInformation:
      "Doimiy Yashash Ruxsatnomasi Ma'lumotlari Yo'q",
    residencePermit: "Doimiy Yashash Ruxsatnomasi",
    fingerprintingInformation: "Barmoq Izlari Ma'lumotlari",
    noFingerprintingInformation: "Barmoq Izlari Ma'lumotlari Yo'q",
    fingerprinting: "Barmoq Izlari",
    dateOfBirth: "Tug'ilgan Sana",
    select: "tanlash",
    status: "Holati",
    addnewproject: "Yangi Loyiha Qo'shish",
    projectid: "Loyiha identifikatori",
    enterenglishprojectname: "Inglizcha Loyiha Nomini Kiriting",
    projectcode: "Loyiha Kodi",
    enterprojectcode: "Loyiha Kodini Kiriting",
    enterprojectid: "loyiha identifikatorini kiriting",
    enterrussianprojectname: "Rossiya Loyiha Nomini Kiriting",
    enterturkishprojectname: "Turkcha Loyiha Nomini Kiriting",
    enterenglishaddress: "Inglizcha Manzilni Kiriting",
    enterrussianaddress: "Rus Manzilini Kiriting",
    enterturkishaddress: "Turkcha Manzilni Kiriting",
    subjectofrf: "RF mavzusi",
    selectsubject: "Mavzuni Tanlang",
    close: "Yopish",
    savechanges: "O'zgarishlarni Saqlang",
    project: "Loyiha",
    editproject: "Loyihani Tahrirlash",
    selectdate: "Sanani Tanlash",
    selectproject: "Loyihani Tanlang",
    addbudgetitem: "Byudjet Elementini Qo'shing",
    submit: "Yuborish",
    back: "Orqaga",
    error: "Xato",
    httperrorstatus: "HTTP xato! Holati: ",
    youdonothaveaccesstoaddbudgetdata:
      "Sizda byudjet ma'lumotlarini qo'shish imkoniyati yo'q.",
    success: "muvaffaqiyat",
    therewasanissuefetchingtheprojectdata:
      "Loyiha ma'lumotlarini olishda muammo bor edi: ",
    budgetitemaddedsuccessfully: "Byudjet moddasi muvaffaqiyatli qo'shildi",
    failedtodownloadprojectdata: "Loyiha ma'lumotlarini yuklab bo'lmadi: ",
    therewasissuewithfetchoperation:
      "Fetch operatsiyasi bilan bog'liq muammo bor edi: ",
    failedtofetchsubjects: "Mavzularni olib bo'lmadi",
    pleaseselectatleastoneitemtodownload:
      "Iltimos, yuklab olish uchun kamida bitta elementni tanlang.",
    failedtocreatenewproject: "Yangi loyiha yaratib bo'lmadi",
    projectaddedsuccessfully: "Loyiha muvaffaqiyatli qo'shildi!",
    failedtoaddproject: "Loyihani qo'shib bo'lmadi: ",
    loading: "Yuklanmoqda",
    therewasanissuefetchingthebudgetdetails:
      "Byudjet tafsilotlarini olish masalasi bor edi: ",
    therewasanissuewiththesaveoperationpleasetryagainlater:
      "Saqlash operatsiyasi bilan bog'liq muammo bor edi. Iltimos, keyinroq qayta urinib ko'ring.",
    newbudgetitemaddedsuccessfully:
      "Yangi byudjet moddasi muvaffaqiyatli qo'shildi",
    unexpectedresponseformat: "Kutilmagan javob formati",
    areyousure: "Ishonchingiz komilmi?",
    onceudeleteituwillnotbeabletorecoverthisbudgetitem:
      "O'chirilgandan so'ng, siz ushbu byudjet elementini tiklay olmaysiz!",
    warning: "ogohlantirish",
    proofyourbudgetitemhasbeendeleted:
      "Poof! Sizning byudjet elementingiz o'chirildi!",
    therewasanissuewiththedeleteoperationpleasetryagainlater:
      "O'chirish operatsiyasi bilan bog'liq muammo bor edi. Iltimos, keyinroq qayta urinib ko'ring.",
    editmydata: "Ma'lumotlarimni tahrirlash",
    selectdiscipline: "Intizomni Tanlang",
    enterbudgetcode: "Byudjet Kodini Kiriting",
    enterprimaveracode: "Primavera Kodini Kiriting",
    selectjobtitle: "Ish nomini tanlang",
    budgetdetails: "Byudjet Tafsilotlari",
    loadingoptions: "Yuklash parametrlari",
    add: "Qo'shish",
    cancel: "Bekor qilish",
    enterinput: "Kirishni Kiriting",
    oncedeletedyouwillnotbeabletorecoverthisproject:
      "O'chirilgandan so'ng, siz ushbu loyihani tiklay olmaysiz!",
    failedtodownloadbudgetdata: "Byudjet ma'lumotlarini yuklab bo'lmadi: ",
    failedtodeletetheproject: "Loyihani o'chirib bo'lmadi",
    proofyourprojecthasbeendeleted: "Poof! Sizning loyihangiz o'chirildi!",
    totalcost: "Umumiy Qiymati",
    author: "Muallif",
    versiondate: "Versiya Sanasi",
    successfull: "Muvaffaqiyatli",
    pending: "Kutilmoqda",
    budgetitemeditedsuccessfully: "Byudjet elementi muvaffaqiyatli tahrirlandi",
    projectupdatedsuccessfully: "Loyiha muvaffaqiyatli yangilandi!",
    failedtoupdateproject: "Loyihani yangilab bo'lmadi",
    failedtoaddtreasure: "Xazina qo'shib bo'lmadi: ",
    anerrorocurred: "Xatolik yuz berdi",
    treasureaddedsuccessfully: "Xazina muvaffaqiyatli qo'shildi!",
    financetreasure: "Moliya Xazinasi",
    addtreasure: "Xazina Qo'shing",
    importtreasure: "Import Xazinasi",
    exporttreasure: "Eksport Xazinasi",
    transaction: "Bitim",
    documentno: "Hujjat raqami",
    type: "Turi",
    operationtype: "Operatsiya Turi",
    company: "Kompaniya",
    counterparty: "Kontragent",
    expenseincome: "Xarajatlar / Daromad",
    contractno: "Shartnoma raqami",
    amount: "Miqdori",
    addnewtreasures: "Yangi Xazina Qo'shing",
    selectoperationtype: "Operatsiya Turini Tanlang",
    counter_party: "Qarshi Partiya",
    bankaccount: "Bank Hisobi",
    bankaccountcp: "Kontragentning bank hisobvarag'i",
    expensetype: "Xarajatlar Turi",
    selectcurrency: "Valyutani Tanlang",
    costcenter: "Xarajatlar Markazi",
    comment: "Izoh",
    paymentdetails: "To'lov Tafsilotlari",
    selectcounterparty: "Qarshi Partiyani Tanlang",
    bank: "Bank",
    treasure: "Xazina",
    vatamount: "QQS Miqdori",
    treasury: "G'aznachilik",
    bankandcash: "Bank va naqd pul",
    financetreasury: "Moliya G'aznachiligi",
    addtreasurybank: "G'aznachilik Bankini Qo'shish",
    importtreasury: "Import G'aznachilik",
    exporttreasury: "Eksport Xazinasi",
    our_company: "Bizning Kompaniya",
    addtreasurycash: "G'aznachilik Naqd Qo'shing",
    addnewtreasury: "Yangi Xazina Qo'shing",
    kbbo: "KBBO",
    code: "kod",
    employeeaddedsuccessfully: "Xodim muvaffaqiyatli qo'shildi!",
    dateofemployment: "Ishga qabul qilingan sana",
    dateofdismissial: "Ishdan bo'shatish sanasi",
    surname: "Familiyasi",
    othername: "Boshqa Ism",
    selectgender: "Jinsni tanlang",
    male: "erkak",
    female: "ayol",
    tinumber: "TIN raqami",
    snilsnumber: "SNILS raqami",
    phonenumber: "Telefon Raqami",
    others: "Boshqalar",
    photo: "Rasm",
    selectposition: "Pozitsiyani tanlang",
    citizenship: "Fuqarolik",
    currentdocumentcategorydata: "Joriy Hujjat Toifasi Ma'lumotlari",
    selectdocument: "Hujjatni tanlang",
    selectcountry: "Mamlakatni tanlang",
    failedtodownloademployeedata: "Xodim ma'lumotlarini yuklab bo'lmadi:",
    oncedeletedyouwillnotbeabletorecoverthisemployee:
      "O'chirilgandan so'ng, siz ushbu xodimni tiklay olmaysiz!",
    failedtodeletetheemployee: "Xodimni o'chirib bo'lmadi",
    proofyouremployeehasbeendeleted: "Poof! Sizning xodimingiz o'chirildi!",
    failedtodeleteemployee: "Xodimni o'chirib bo'lmadi",
    excelfileuploadedsuccessfully: "Excel fayli muvaffaqiyatli Yuklandi!",
    failedtouploadexcelfile: "Excel faylini yuklab bo'lmadi.",
    upload: "Yuklash",
    uploading: "Yuklanmoqda",
  },
  en: {
    invoiceNumber: "Invoice Number",
    createdAt: "Created at",
    dueDate: "Due Date",
    subTotal: "Sub total",
    total: "Total",
    invoiceStatus: "Invoice Status",
    paymentStatus: "Payment Status",
    plan: "Plan",
    duration: "Duration",
    numberofEmployee: "Number of employee",
    employeeInPlain2do: "Employee in plain2do",
    editTimesheetDays: "Edit adding time",
    noKPP: "No KPP",
    companySphere: "Company Sphere",
    nameofCompany: "Name of Company",
    enteraNumber: "Enter a number",
    subtextTimesheet: "Please enter the number of days to add valid timesheet.",
    companyDetails: "Company details",
    enterInnofCompany: "Enter INN of company",
    /////
    editmyData: "Edit my data",
    editaddingTime: "Edit adding time",
    addCompany: "Add company",
    addUser: "Add user",
    systemAdmin: "System Admin",
    projectadmin: "Project Admin",
    budgetUser: "Budget User",
    budgetManager: "Budget Manager",
    hrUser: "HR User",
    hrManager: "HR Manager",
    timekeeper: "Timekeeper",
    emailOfUser: "Email of user",
    filterbyemail: "Filter by email...",
    allUserTypes: "All user types",
    date: "Date",
    subsPlan: "Subscription Plan",
    accepted: "Accepted",
    notaccepted: "Not accepted",
    dateinvited: "Date Invited",
    userType: "User type",
    invitedUser: "Invited Users",
    logout: "Logout",
    companyList: "Company List",
    invoices: "Invoices",
    profile: "Profile",
    hi: "Hi",
    basis: "Basis",
    manager: "Manager",
    snils: "SNILS",
    noSnils: "No SNILS Information",
    edit: "Edit",
    payrollHistory: "Payroll History",
    editPersonalInfo: "Edit Personal Information",
    employeeActivation: "Employee Activation",
    cash: "Cash",
    enterothers: "Enter some notes here...(optional)",
    enterInn: "Enter 12 digits number",
    enterOthername: "Enter Middle name",
    enterFirstname: "Enter your Firstname",
    enterSurname: "Enter your Surname",
    enterNumber: "Enter a Personal Number",
    searchPlaceholder: "Search here...",
    dashboard: "Dashboard",
    generateReport: "Generate Report",
    projects: "Projects",
    listProjects: "Projects",
    budgets: "Budgets",
    ganttChart: "Gantt Chart",
    laborDemand: "Labor Demand List",
    myTasks: "My Tasks",
    kanbanBoard: "Kanban Board",
    pendingTasks: "Pending Tasks",
    employee: "Employee",
    employeeGeneralList: "Employee General List",
    employeeCard: "Employee Card",
    personalInformation: "Personal Information",
    employeeDocuments: "Employee Documents",
    certificates: "Certificates",
    costCentreTransferHistory: "Salary and Transfer Info",
    timesheet: "Timesheet",
    payroll: "Payroll",
    dashboards: "Dashboards",
    statistics: "Statistics",
    analyticalReports: "Analytical Reports",
    projectDatatables: "List of Projects",
    downloadProjects: "Download",
    addProject: "Add Project",
    projectsPerPage: "Projects Per Page",
    projectName: "Project Name",
    address: "Address",
    startDate: "Start Date",
    endDate: "End Date",
    action: "Action",
    budgetData: "Budget Data",
    downloadBudgets: "Download Budgets",
    addBudget: "Add Budget",
    budgetsPerPage: "Budgets Per Page",
    budgetVersion: "Budget Version",
    budgetCode: "Budget Code",
    next: "Next Page",
    previous: "Previous Page",
    salary: "Salary",
    primaveraCode: "Primavera Code",
    pagination: "Page",
    paginationOf: "of",
    discipline: "Discipline",
    jobTitle: "Job Title",
    employeeClass: "Employee Class",
    employeeLevel: "Employee Level",
    documentType: "Document Type",
    currency: "Currency",
    unitOfMeasure: "Unit of Measure",
    employeeQuantity: "Employee Quantity",
    employeeSalary: "Employee Salary",
    dayDifference: "Day difference",
    monthDifference: "Month difference",
    yearDifference: "Year difference",
    taxes: "Taxes",
    legalExpenses: "Legal Expenses",
    actions: "Actions",
    select_all: "Select All",
    deselect_all: "Deselect All",
    all: "All",
    active: "Active",
    inactive: "Inactive",
    personal_number: "Personal Number",
    first_name: "First Name",
    position: "Job title",
    mobile_number: "Mobile Number",
    current_status: "Status",
    employeePerPage: "Rows per page",
    addEmployee: "Add Employee",
    downloadEmployee: "Download Employee",
    employeesList: "Employees List",
    employees: "Employees",
    latest: "Latest",
    archive: "Archive",
    passportInformation: "Passport",
    noPassportInformation: "No Passport Information",
    passport: "Passport",
    countryCode: "Country Code",
    passportNumber: "Passport Number",
    givenNames: "Given Names",
    nationality: "Nationality",
    birthDate: "Birth Date",
    gender: "Gender",
    dateOfExpiry: "Date of Expiry",
    dateOfIssue: "Date of Issue",
    passportPhotoAlt: "Passport Photo",
    noData: "There is nothing to show",
    visaInformation: "Visa",
    noVisaInformation: "No Visa Information",
    visa: "Visa",
    visaNumber: "Visa Number",
    nameSurname: "Name Surname",
    fromDate: "From Date",
    validityDate: "Validity Date",
    issuedBy: "Issued By",
    durationOfStay: "Duration Of Stay",
    visaPhotoAlt: "Visa Photo",
    patentInformation: "Patent",
    noPatentInformation: "No Patent Information",
    patent: "Patent",
    registerSerialNumber: "Register Serial Number",
    patentPhotoAlt: "Patent Photo",
    innInformation: "INN",
    noInnInformation: "No INN Information",
    inn: "INN",
    documentNumber: "Document Number",
    employeeNumber: "Employee Number",
    tinNumber: "TIN Number",
    registrationInformation: "Registration",
    noRegistrationInformation: "No Registration Information",
    registration: "Registration",
    registrationAddress: "Registration Address",
    registrationInDormitory: "Registration in Dormitory",
    dormitory: "Dormitory",
    medicalInformation: "Medical Assurance",
    noMedicalInformation: "No Medical Information",
    medicalCheckup: "Medical Checkup",
    documentSerie: "Document Serie",
    migrationInformation: "Migration Card",
    noMigrationInformation: "No Migration Card Information",
    migrationCard: "Migration Card",
    patents: "Patents",
    certificationsInformation: "Certificates",
    noCertificationsInformation: "No Certificate Information",
    certifications: "Certifications",
    certificationName: "Certification Name",
    residencePermitInformation: "Residence Permit",
    noResidencePermitInformation: "No Residence Permit Information",
    residencePermit: "Residence Permit",
    fingerprintingInformation: "Dactyloscopy",
    noFingerprintingInformation: "No Dactyloscopy Information",
    fingerprinting: "Dactyloscopy",
    dateOfBirth: "Date of Birth",
    select: "select",
    status: "Status",
    addnewproject: "Add New Project",
    projectid: "Project ID",
    enterenglishprojectname: "Enter English Project Name",
    projectcode: "Project Code",
    enterprojectcode: "Enter Project Code",
    enterprojectid: "Enter Project ID",
    enterrussianprojectname: "Enter Russian Project Name",
    enterturkishprojectname: "Enter Turkish Project Name",
    enterenglishaddress: "Enter English Address",
    enterrussianaddress: "Enter Russian Address",
    enterturkishaddress: "Enter Turkish Address",
    subjectofrf: "Region of RF",
    selectsubject: "Select region",
    close: "Close",
    savechanges: "Save Changes",
    project: "Project",
    editproject: "Edit Project",
    selectdate: "Select Date",
    selectproject: "Select Project",
    addbudgetitem: "Add Budget Item",
    submit: "Submit",
    back: "Back",
    error: "error",
    httperrorstatus: "HTTP error! Status: ",
    youdonothaveaccesstoaddbudgetdata:
      "You do not have access to add budget data.",
    success: "success",
    therewasanissuefetchingtheprojectdata:
      "There was an issue fetching the project data: ",
    budgetitemaddedsuccessfully: "Budget item added successfully",
    failedtodownloadprojectdata: "Failed to download project data: ",
    therewasissuewithfetchoperation:
      "There was an issue with the fetch operation: ",
    failedtofetchsubjects: "Failed to fetch subjects",
    pleaseselectatleastoneitemtodownload:
      "Please select at least one item to download.",
    failedtocreatenewproject: "Failed to create new project",
    projectaddedsuccessfully: "Project added successfully!",
    failedtoaddproject: "Failed to add project: ",
    loading: "Loading",
    therewasanissuefetchingthebudgetdetails:
      "There was an issue fetching the budget details: ",
    therewasanissuewiththesaveoperationpleasetryagainlater:
      "There was an issue with the save operation. Please try again later.",
    newbudgetitemaddedsuccessfully: "New budget item added successfully",
    unexpectedresponseformat: "Unexpected response format",
    areyousure: "Are you sure?",
    onceudeleteituwillnotbeabletorecoverthisbudgetitem:
      "Once deleted, you will not be able to recover this budget item!",
    warning: "warning",
    proofyourbudgetitemhasbeendeleted:
      "Poof! Your budget item has been deleted!",
    therewasanissuewiththedeleteoperationpleasetryagainlater:
      "There was an issue with the delete operation. Please try again later.",
    editmydata: "Edit my data",
    selectdiscipline: "Select Discipline",
    enterbudgetcode: "Enter Budget Code",
    enterprimaveracode: "Enter Primavera Code",
    selectjobtitle: "Select Job title",
    budgetdetails: "Budget Details",
    loadingoptions: "Loading options",
    add: "Add",
    cancel: "Cancel",
    enterinput: "Enter input",
    oncedeletedyouwillnotbeabletorecoverthisproject:
      "Once deleted, you will not be able to recover this project!",
    failedtodownloadbudgetdata: "Failed to download budget data: ",
    failedtodeletetheproject: "Failed to delete the project",
    proofyourprojecthasbeendeleted: "Poof! Your project has been deleted!",
    totalcost: "Total Cost",
    author: "Author",
    versiondate: "Version Date",
    successfull: "Successful",
    pending: "Pending",
    budgetitemeditedsuccessfully: "Budget item edited successfully",
    projectupdatedsuccessfully: "Project updated successfully!",
    failedtoupdateproject: "Failed to update project",
    failedtoaddtreasure: "Failed to add treasure: ",
    anerrorocurred: "An error occurred",
    treasureaddedsuccessfully: "Treasure added successfully!",
    financetreasure: "Finance Treasure",
    addtreasure: "Add Treasure",
    importtreasure: "Import Treasure",
    exporttreasure: "Export Treasure",
    transaction: "Transaction",
    documentno: "Document No",
    type: "Type",
    operationtype: "Operation Type",
    company: "Company",
    counterparty: "Counterparty",
    expenseincome: "Expense/Income",
    contractno: "Contract No",
    amount: "Amount",
    addnewtreasures: "Add New Treasure",
    selectoperationtype: "Select Operation Type",
    counter_party: "Counter Party",
    bankaccount: "Bank Account",
    bankaccountcp: "Bank Account CP",
    expensetype: "Expense Type",
    selectcurrency: "Select Currency",
    costcenter: "Cost Center",
    comment: "Comment",
    paymentdetails: "Payment Details",
    selectcounterparty: "Select Counter Party",
    bank: "Bank",
    treasure: "Treasure",
    vatamount: "VAT Amount",
    treasury: "Treasury",
    bankandcash: "Bank and Cash",
    financetreasury: "Finance Treasury",
    finance: "Finance",
    addtreasurybank: "Add Operation",
    importtreasury: "Upload",
    exporttreasury: "Download",
    our_company: "Our Company",
    addtreasurycash: "Add Cash Operation",
    addnewtreasury: "Add Bank Operation",
    kbbo: "KBBO",
    code: "code",
    employeeaddedsuccessfully: "Employee added successfully!",
    dateofemployment: "Date of Employment",
    dateofdismissial: "Date of Dismissal",
    surname: "Surname",
    othername: "Middle name",
    selectgender: "Select gender",
    male: "Male",
    female: "Female",
    tinumber: "TIN Number",
    snilsnumber: "SNILS Number",
    phonenumber: "Phone Number",
    others: "Others",
    photo: "Photo",
    selectposition: "Select a position",
    citizenship: "Citizenship",
    currentdocumentcategorydata: "Current Document Category Data",
    selectdocument: "Select a Document",
    selectcountry: "Select a country",
    failedtodownloademployeedata: "Failed to download employee data: ",
    oncedeletedyouwillnotbeabletorecoverthisemployee:
      "Once deleted, you will not be able to recover this employee!",
    failedtodeletetheemployee: "Failed to delete the employee",
    proofyouremployeehasbeendeleted: "Poof! Your employee has been deleted!",
    failedtodeleteemployee: "Failed to delete employee",
    excelfileuploadedsuccessfully: "Excel file uploaded successfully!",
    failedtouploadexcelfile: "Failed to upload Excel file.",
    upload: "Upload",
    uploading: "Uploading",
    enteryourpassportnumber: "Enter your passport number",
    issuedby: "Issued By",
    issuedate: "Issue Date",
    validitydate: "Validity Date",
    personalidentificationnumber: "Personal Identification number",
    enterpersonalidentificationnumber: "Enter Personal Identification number",
    nochanges: "No Changes",
    therearenochangestosave: "There are no changes to save.",
    personalinformationupdatedsuccessfully:
      "Personal information updated successfully!",
    therewasanissueupdatingtheinformation:
      "There was an issue updating the information: ",
    save: "Save",
    employmentdocuments: "Employee Documents",
    salarytransferinfo: "Salary & Transfer Info",
    vat: "VAT",
    selectmanager: "Select Manager",
    addpayrollhistory: "Add Salary and Transfer Info",
    selectdocumenttype: "Select Document type",
    selectunitofmeasure: "Select Unit of Measure",
    startofworkdate: "Start of Work date",
    endofworkdate: "End of Work date",
    selectworkschedule: "Select Work Schedule",
    jobtitletype: "Job Title type",
    currencytype: "Currency type",
    selectpaymenttype: "Select Payment Type",
    selectcostcentre: "Select Cost Centre",
    editsalarytransferhistory: "Edit Salary & Transfer history",
    oncedeletedyouwillnotbeabletorecoverthisrecord:
      "Once deleted, you will not be able to recover this record!",
    failedtodeleterecordserverrespondedwithstatus:
      "Failed to delete record: Server responded with status",
    failedtodeleterecord: "Failed to delete record:",
    deleted: "Deleted!",
    thepayrollrecordhasbeendeleted: "The payroll record has been deleted.",
    editabledatasuccessfullychanged: "Editable data successfully changed!",
    payrollhistoryaddedsuccessfully: "Payroll history added successfully!",
    description: "Description",
    saldo: "Saldo",
    payrollhistroyuser: "Payroll Histroy User",
    dissmisaldate: "Dismissal date",
    addnewrlcertification: "Add New RL Certification",
    placeofissue: "Place of Issue",
    editrlcertification: "Edit RL Certification",
    adddaktilrecord: "Add Dactyloscopy Record",
    issueby: "Issue By",
    addrlcertificate: "Add RL Certificate",
    editdaktilinformation: "Edit Dactyloscopy Information",
    addfingerprintinformation: "Add FingerPrint Information",
    editinninformation: "Edit INN Information",
    documentNoSNILSFormat: "Document No (SNILS Format)",
    format: "Format",
    addnewinninformation: "Add New INN Information",
    documentNoINNFormat: "Document No (INN Format)",
    addinn: "Add Inn",
    addnewregistrationinformation: "Add New Registration Information",
    editmedicalinformation: "Edit Medical Information",
    documentseria: "Document seria",
    addmedicalinformation: "Add Medical Information",
    addmigrationinformation: "Add Migration Information",
    todate: "To Date",
    visitpurpose: "Visit Purpose",
    bordercheckpoint: "Border Checkpoint",
    editmigrationinformation: "Edit Migration Information",
    editpassportinformation: "Edit Passport Information",
    passportserie: "Passport Serie",
    countrypersnumber: "Country Pers Number",
    letter: "Letter",
    latin: "Latin",
    cyrillic: "Cyrillic",
    addnewpassportinformation: "Add New Passport Information",
    selectnationality: "Select Nationality",
    selectissuedby: "Select Issued By",
    addpassport: "Add Passport",
    editpatentinformation: "Edit Patent Information",
    specialmarks: "Special Marks",
    validterritories: "Valid Territories",
    addnewpatentinformation: "Add New Patent Information",
    addpatent: "Add Patent",
    addnewvnjRvprecord: "Add New VnjRvp Record",
    decisiondate: "Decision Date",
    decisionnumber: "Decision Number",
    entera2digitnumber: 'Enter a 2-digit number or "No"',
    editVnjRvprecord: "Edit VnjRvp Record",
    validdate: "Valid Date",
    eddresidencepermitinformation: "Add Residence Permit Information",
    selectdormitory: "Select Dormitory",
    editregistrationinformation: "Edit Registration Information",
    addregistration: "Add Registration",
    editSNILSinformation: "Edit SNILS Information",
    addnewSNILSinformation: "Add New SNILS Information",
    pleaseentertheSNILSintheformat:
      "Please enter the SNILS in the format XXX-XXX-XXX XX",
    addsnils: "Add Snils",
    editvisainformation: "Edit Visa Information",
    visaseria: "Visa Seria",
    visano: "Visa No",
    addnewvisainformation: "Add New Visa Information",
    addvisa: "Add Visa",
    thereisnoaddressavailable: "There is no address available",
  },
  tr: {
    invoiceNumber: "Fatura Numarası",
    createdAt: "Oluşturulma Tarihi",
    dueDate: "Son Ödeme Tarihi",
    subTotal: "Ara Toplam",
    total: "Toplam",
    invoiceStatus: "Fatura Durumu",
    paymentStatus: "Ödeme Durumu",
    plan: "Plan",
    duration: "Süre",
    numberofEmployee: "Çalışan sayısı",
    employeeInPlain2do: "Plain2do'daki çalışan",
    editTimesheetDays: "Çizelgeyi düzenle",
    noKPP: "KPP Yok",
    companySphere: "Şirket Alanı",
    nameofCompany: "Şirketin Adı",
    enteraNumber: "Bir sayı girin",
    subtextTimesheet:
      "Geçerli bir zaman çizelgesi eklemek için lütfen gün sayısını girin.",
    companyDetails: "Şirket detayları",
    enterInnofCompany: "Şirketin INN'sini girin",
    editaddingTime: "Ekleme zamanını düzenle",
    addCompany: "Şirket ekle",
    addUser: "Kullanıcı ekle",
    systemAdmin: "Sistem Yöneticisi",
    projectadmin: "Proje Yöneticisi",
    budgetUser: "Bütçe Kullanıcısı",
    budgetManager: "Bütçe Yöneticisi",
    hrUser: "İK Kullanıcısı",
    hrManager: "İK Yöneticisi",
    timekeeper: "Zaman Tutucu",
    emailOfUser: "Kullanıcının e-posta adresi",
    filterbyemail: "E-postaya göre filtrele...",
    allUserTypes: "Tüm kullanıcı türleri",
    date: "Tarih",
    subsPlan: "Abonelik Planı",
    accepted: "Kabul edildi",
    notaccepted: "Kabul Edilmedi",
    dateinvited: "Davet tarihi",
    userType: "Kullanıcı Türü",
    invitedUser: "Davet edilen kullanıcılar",
    logout: "Oturumu kapat",
    companyList: "Firma Listesi",
    invoices: "Faturalar",
    profile: "Profil",
    hi: "Merhaba",
    basis: "Esas",
    manager: "Yönetici",
    snils: "SNILS",
    noSnils: "SNILS Bilgisi Yok",
    edit: "Düzenle",
    payrollHistory: "Bordro geçmişi",
    editPersonalInfo: "Kişisel Bilgileri Düzenle",
    employeeActivation: "Personel Aktivasyonu",
    cash: "Peşin",
    enterothers: "Buraya bazı notlar girin...(isteğe bağlı)",
    enterInn: "12 haneli sayıyı girin",
    enterOthername: "Baba adınızı girin",
    enterFirstname: "Adınızı girin",
    enterSurname: "Soyadınızı girin",
    enterNumber: "Kişisel Numara Girin",
    finance: "Finans",
    searchPlaceholder: "Burada ara...",
    dashboard: "Tableau de bord",
    generateReport: "Générer le rapport",
    projects: "Projeler",
    listProjects: "Projeler",
    budgets: "Bütçeler",
    ganttChart: "Gantt Çizelgesi",
    laborDemand: "İşgücü Talep Listesi",
    myTasks: "Görevlerim",
    kanbanBoard: "Kanban Panosu",
    pendingTasks: "Bekleyen Görevler",
    employee: "Personel",
    employeeGeneralList: "Personel Listesi",
    employeeCard: "Personel Kartı",
    personalInformation: "Kişisel Bilgiler",
    employeeDocuments: "Personel Belgeleri",
    certificates: "Sertifikalar",
    costCentreTransferHistory: "Maaş ve Transfer",
    timesheet: "Puantaj",
    payroll: "Maaş Bordrosu",
    dashboards: "Panolar",
    statistics: "İstatistikler",
    analyticalReports: "Analitik Raporlar",
    projectDatatables: "Projeler Listesi",
    downloadProjects: "İndir",
    addProject: "Proje ekle",
    projectsPerPage: "Sayfa başına projeler",
    projectName: "Proje Adı",
    address: "Adres",
    startDate: "Başlangıç ​​tarihi",
    endDate: "Bitiş tarihi",
    action: "Aksiyon",
    budgetData: "Bütçe Verileri",
    downloadBudgets: "Bütçeleri İndir",
    addBudget: "Bütçe Ekle",
    budgetsPerPage: "Sayfa Başına Bütçeler",
    budgetVersion: "Bütçe Versiyonu",
    budgetCode: "Bütçe Kodu",
    next: "Sonraki Sayfa",
    previous: "Önceki sayfa",
    salary: "Maaş",
    primaveraCode: "Primavera Kodu",
    pagination: "Sayfa",
    paginationOf: "/",
    discipline: "Disiplin",
    jobTitle: "İş Başlığı",
    employeeClass: "Personel Sınıfı",
    employeeLevel: "Personel Seviyesi",
    documentType: "Belge Türü",
    currency: "Para Birimi",
    unitOfMeasure: "Ölçü Birimi",
    employeeQuantity: "Personel Miktarı",
    employeeSalary: "Personel Maaşı",
    dayDifference: "Gün Farkı",
    monthDifference: "Ay Farkı",
    yearDifference: "Yıl Farkı",
    taxes: "Vergiler",
    legalExpenses: "Yasal Giderler",
    actions: "Aksiyon",
    select_all: "Tümünü Seç",
    deselect_all: "Seçimi Kaldır",
    all: "Hepsi",
    active: "Aktif",
    inactive: "Pasif",
    personal_number: "Sicil No",
    surname: "Soyadı",
    first_name: "Adı",
    position: "Görevi",
    mobile_number: "Cep Telefonu",
    current_status: "Durum",
    employeePerPage: "Sayfadaki kayıt",
    addEmployee: "Personel Ekle",
    downloadEmployee: "Personel İndir",
    employeesList: "Personel Listesi",
    employees: "Personellar",
    latest: "Aktif",
    archive: "Arşiv",
    passportInformation: "Pasaport",
    noPassportInformation: "Pasaport Bilgisi Yok",
    passport: "Pasaport",
    countryCode: "Ülke Kodu",
    passportNumber: "Pasaport Numarası",
    givenNames: "Adlar",
    nationality: "Uyruk",
    birthDate: "Doğum Tarihi",
    gender: "Cinsiyet",
    dateOfExpiry: "Son Kullanma Tarihi",
    dateOfIssue: "Veriliş Tarihi",
    passportPhotoAlt: "Pasaport Fotoğrafı",
    noData: "Gösterilecek bir şey yok",
    visaInformation: "Vize",
    noVisaInformation: "Vize Bilgisi Yok",
    visa: "Vize",
    visaNumber: "Vize Numarası",
    nameSurname: "Adı Soyadı",
    issueDate: "Veriliş Tarihi",
    fromDate: "Başlangıç Tarihi",
    validityDate: "Geçerlilik Tarihi",
    issuedBy: "Veriliş Yeri",
    durationOfStay: "Kalış Süresi",
    visaPhotoAlt: "Vize Fotoğrafı",
    patentInformation: "Patent",
    noPatentInformation: "Patent Bilgisi Yok",
    patent: "Patent",
    registerSerialNumber: "Kayıt Seri Numarası",
    patentPhotoAlt: "Patent Fotoğrafı",
    innInformation: "INN",
    noInnInformation: "INN Bilgisi Yok",
    inn: "INN",
    employeeNumber: "Personel Numarası",
    tinNumber: "TIN Numarası",
    registrationInformation: "Kayıt",
    noRegistrationInformation: "Kayıt Bilgisi Yok",
    registration: "Kayıt",
    registrationAddress: "Kayıt Adresi",
    registrationInDormitory: "Yurt Kaydı",
    dormitory: "Yurt",
    medicalInformation: "Sağlık kontrolü",
    noMedicalInformation: "Tıbbi Bilgi Yok",
    medicalCheckup: "Tıbbi Muayene",
    documentSerie: "Belge Serisi",
    migrationInformation: "Göçmen Kart",
    noMigrationInformation: "Göçmen Kart Bilgisi Yok",
    migrationCard: "Göçmen Kartı",
    patents: "Patentler",
    certificationsInformation: "Sertifikalar",
    noCertificationsInformation: "Sertifika Bilgisi Yok",
    certifications: "Sertifikalar",
    certificationName: "Sertifika Adı",
    residencePermitInformation: "İkamet İzni",
    noResidencePermitInformation: "İkamet İzni Bilgisi Yok",
    residencePermit: "İkamet İzni",
    fingerprintingInformation: "Parmak İzi",
    noFingerprintingInformation: "Parmak İzi Bilgisi Yok",
    fingerprinting: "Parmak İzi",
    dateOfBirth: "Doğum Tarihi",
    select: "seç",
    status: "Durum",
    addnewproject: "Yeni Proje Ekle",
    projectid: "Proje ID'si",
    enterenglishprojectname: "İngilizce Proje Adını Girin",
    projectcode: "Proje Kodu",
    enterprojectcode: "Proje Kodunu Girin",
    enterprojectid: "proje kimliğini girin",
    enterrussianprojectname: "Rusça Proje Adını Girin",
    enterturkishprojectname: "Türkçe Proje Adını Girin",
    enterenglishaddress: "İngilizce adresini Girin",
    enterrussianaddress: "Rus Adresini Girin",
    enterturkishaddress: "Türk Adresini Girin",
    subjectofrf: "RF Bölgesi",
    selectsubject: "Bölge Seç",
    close: "Kapat",
    savechanges: "Değişiklikleri Kaydet",
    project: "Proje",
    editproject: "Projeyi Düzenle",
    selectdate: "Tarih Seç",
    selectproject: "Proje Seç",
    addbudgetitem: "Bütçe Öğesi Ekle",
    submit: "Sunmak",
    back: "Geri",
    error: "Hata",
    httperrorstatus: "HTTP hatası! Durum: ",
    youdonothaveaccesstoaddbudgetdata:
      "Bütçe verileri eklemek için erişiminiz yok.",
    success: "başarı",
    therewasanissuefetchingtheprojectdata:
      "Proje verilerini getirirken bir sorun oluştu: ",
    budgetitemaddedsuccessfully: "Bütçe kalemi başarıyla eklendi",
    failedtodownloadprojectdata: "Proje verileri indirilemedi: ",
    therewasissuewithfetchoperation:
      "Getirme işlemi ile ilgili bir sorun vardı: ",
    failedtofetchsubjects: "Denekler getirilemedi",
    pleaseselectatleastoneitemtodownload:
      "Lütfen indirmek için en az bir öğe seç.",
    failedtocreatenewproject: "Yeni proje oluşturulamadı",
    projectaddedsuccessfully: "Proje başarıyla eklendi!",
    failedtoaddproject: "Proje eklenemedi: ",
    loading: "Yükleniyor",
    therewasanissuefetchingthebudgetdetails:
      "Bütçe detaylarını getiren bir sorun vardı: ",
    therewasanissuewiththesaveoperationpleasetryagainlater:
      "Kaydetme işlemi ile ilgili bir sorun vardı. Lütfen daha sonra tekrar deneyin.",
    newbudgetitemaddedsuccessfully: "Yeni bütçe kalemi başarıyla eklendi",
    unexpectedresponseformat: "Beklenmeyen yanıt biçimi",
    areyousure: "Emin misin?",
    onceudeleteituwillnotbeabletorecoverthisbudgetitem:
      "Silindikten sonra, bu bütçe kalemini kurtaramayacaksınız!",
    warning: "uyarı",
    proofyourbudgetitemhasbeendeleted: "Puf! Bütçe kaleminiz silindi!",
    therewasanissuewiththedeleteoperationpleasetryagainlater:
      "Silme işlemiyle ilgili bir sorun vardı. Lütfen daha sonra tekrar deneyin.",
    editmydata: "Verilerimi düzenle",
    selectdiscipline: "Disiplini Seç",
    enterbudgetcode: "Bütçe Kodunu Girin",
    enterprimaveracode: "Primavera Kodunu Girin",
    selectjobtitle: "İş unvanını seç",
    budgetdetails: "Bütçe Detayları",
    loadingoptions: "Yükleme seçenekleri",
    add: "Ekle",
    cancel: "İptal",
    enterinput: "Girişi Girin",
    oncedeletedyouwillnotbeabletorecoverthisproject:
      "Silindikten sonra bu projeyi kurtaramayacaksınız!",
    failedtodownloadbudgetdata: "Bütçe verileri indirilemedi: ",
    failedtodeletetheproject: "Proje silinemedi",
    proofyourprojecthasbeendeleted: "Puf! Projeniz silindi!",
    totalcost: "Toplam Maliyet",
    author: "Yazar",
    versiondate: "Versiyon Tarihi",
    successfull: "Başarılı",
    pending: "Askıda olan",
    budgetitemeditedsuccessfully: "Bütçe kalemi başarıyla düzenlendi",
    projectupdatedsuccessfully: "Proje başarıyla güncellendi!",
    failedtoupdateproject: "Proje güncellenemedi",
    failedtoaddtreasure: "Hazine eklenemedi: ",
    anerrorocurred: "Bir hata oluştu",
    treasureaddedsuccessfully: "Hazine başarıyla eklendi!",
    financetreasure: "Finans Hazinesi",
    addtreasure: "Hazine Ekle",
    importtreasure: "Hazineyi içe Aktar",
    exporttreasure: "İhracat Hazinesi",
    transaction: "İşlem",
    documentno: "Belge numarası",
    type: "Tip",
    operationtype: "İşlem Türü",
    company: "Şirket",
    counterparty: "Karşı Taraf",
    expenseincome: "Gider / Gelir",
    contractno: "Sözleşme numarası",
    amount: "Tutar",
    addnewtreasures: "Banka İşlemi Ekle",
    selectoperationtype: "İşlem Türünü Seç",
    counter_party: "Karşı Taraf",
    bankaccount: "Banka Hesabı",
    bankaccountcp: "Karşı tarafın banka hesabı",
    expensetype: "Gider Türü",
    selectcurrency: "Para Birimi Seç",
    costcenter: "Masraf merkezi",
    comment: "Yorum",
    paymentdetails: "Ödeme Detayları",
    selectcounterparty: "Karşı Tarafı Seç",
    bank: "Banka",
    treasure: "Hazine",
    vatamount: "KDV Tutari",
    treasury: "Hazine",
    bankandcash: "Banka ve Nakit",
    financetreasury: "Hazine",
    addtreasurybank: "Hazine Bankası Ekle",
    importtreasury: "Yükle",
    exporttreasury: "İndir",
    our_company: "Firmamız",
    addtreasurycash: "G'aznachilik Naqd Qo'shing",
    addnewtreasury: "Banka İşlemi Ekle",
    kbbo: "KBBO",
    code: "kod",
    employeeaddedsuccessfully: "Personel başarıyla eklendi!",
    dateofemployment: "İstihdam Tarihi",
    dateofdismissial: "İşten çıkarılma Tarihi",
    surname: "Soyadı",
    othername: "Baba Adı",
    selectgender: "Cinsiyet seç",
    male: "erkek",
    female: "kadın",
    tinumber: "TIN Numarası",
    snilsnumber: "SNILS Numarası",
    phonenumber: "Telefon Numarası",
    others: "Diğerleri",
    photo: "Fotoğraf",
    selectposition: "Bir pozisyon seç",
    citizenship: "Vatandaşlık",
    currentdocumentcategorydata: "Geçerli Belge Kategorisi Verileri",
    selectdocument: "Bir Belge Seç",
    selectcountry: "Bir ülke seç",
    failedtodownloademployeedata: "Personel verileri indirilemedi:",
    oncedeletedyouwillnotbeabletorecoverthisemployee:
      "Silindikten sonra bu Personelı kurtaramayacaksınız!",
    failedtodeletetheemployee: "Personel silinemedi",
    proofyouremployeehasbeendeleted: "Puf! Personelınız silindi!",
    failedtodeleteemployee: "Personel silinemedi",
    excelfileuploadedsuccessfully: "Excel dosyası başarıyla yüklendi!",
    failedtouploadexcelfile: "Excel dosyası yüklenemedi.",
    upload: "Yükle",
    uploading: "yükleniyor",
    vat: "KDV",
    enteryourpassportnumber: "Pasaport numaranızı girin",
    issuedby: "Verilen makam",
    issuedate: "Verilen Tarihi",
    validitydate: "Geçerlilik Tarihi",
    personalidentificationnumber: "Kişisel Kimlik numarası",
    enterpersonalidentificationnumber: "Kişisel Kimlik numaranızı girin",
    nochanges: "Değişiklik Yok",
    therearenochangestosave: "Kaydedilecek değişiklik yok.",
    personalinformationupdatedsuccessfully:
      "Kişisel bilgiler başarıyla güncellendi!",
    therewasanissueupdatingtheinformation:
      "Bilgileri güncellerken bir sorun oluştu: ",
    save: "Kaydet",
    employmentdocuments: "İstihdam Belgeleri",
    salarytransferinfo: "Maaş ve Transfer Bilgileri",
    selectmanager: "Yöneticiyi Seç",
    addpayrollhistory: "Bordro Geçmişi Ekle",
    selectdocumenttype: "Belge türünü seç",
    selectunitofmeasure: "Ölçü Birimini Seç",
    startofworkdate: "İşe başlama tarihi",
    endofworkdate: "İş bitiş tarihi",
    selectworkschedule: "Çalışma grafiğini seç",
    jobtitletype: "İş unvanı türü",
    currencytype: "Para birimi türü",
    selectpaymenttype: "Ödeme Türünü Seç",
    selectcostcentre: "Maliyet Merkezini Seç",
    editsalarytransferhistory: "Maaş ve Transfer geçmişini düzenle",
    oncedeletedyouwillnotbeabletorecoverthisrecord:
      "Silindikten sonra bu kaydı kurtaramayacaksınız!",
    failedtodeleterecordserverrespondedwithstatus:
      "Kayıt silinemedi: Sunucu durumuyla yanıt verdi",
    failedtodeleterecord: "Kayıt silinemedi:",
    deleted: "Silindi!",
    thepayrollrecordhasbeendeleted: "Bordro kaydı silindi.",
    editabledatasuccessfullychanged:
      "Düzenlenebilir veriler başarıyla değiştirildi!",
    payrollhistoryaddedsuccessfully: "Bordro geçmişi başarıyla eklendi!",
    description: "Tanım",
    saldo: "Saldo'nun",
    payrollhistroyuser: "Bordro Geçmişi Kullanıcısı",
    dissmisaldate: "İşten çıkış tarihi",
    addnewrlcertification: "Yeni RL Sertifikası Ekle",
    placeofissue: "Yayın Yeri",
    editrlcertification: "RL Sertifikasını Düzenle",
    adddaktilrecord: "Daktiloskopi Kaydı Ekle",
    issueby: "Yayınlayan",
    addrlcertificate: "RL Sertifikası Ekle",
    editdaktilinformation: "Daktiloskopi Bilgilerini Düzenle",
    addfingerprintinformation: "Parmak İzi Bilgisi Ekleme",
    editinninformation: "INN Bilgilerini Düzenle",
    documentNoSNILSFormat: "Belge No (SALYANGOZ Formatı)",
    format: "Biçim",
    addnewinninformation: "Yeni Han Bilgileri Ekle",
    documentNoINNFormat: "Belge No (INN Formatı)",
    addinn: "Han Ekle",
    addnewregistrationinformation: "Yeni Kayıt Bilgileri Ekle",
    editmedicalinformation: "Tıbbi Bilgileri Düzenle",
    documentseria: "Belge serisi",
    addmedicalinformation: "Tıbbi Bilgi Ekle",
    addmigrationinformation: "Geçiş Bilgileri Ekleme",
    todate: "Tarihe kadar",
    visitpurpose: "Ziyaret Amacı",
    bordercheckpoint: "Sınır Kontrol Noktası",
    editmigrationinformation: "Geçiş Bilgilerini Düzenleme",
    editpassportinformation: "Pasaport Bilgilerini Düzenleme",
    passportserie: "Pasaport Serisi",
    countrypersnumber: "Ülke Pers Numarası",
    letter: "Mektup",
    latin: "Latince",
    cyrillic: "Kiril",
    addnewpassportinformation: "Yeni Pasaport Bilgileri Ekle",
    selectnationality: "Uyruğu Seç",
    selectissuedby: "Tarafından Verilen'i Seç",
    addpassport: "Tarafından Verilen'i Seç",
    editpatentinformation: "Hasta Bilgilerini Düzenleme",
    specialmarks: "Özel İşaretler",
    validterritories: "Geçerli Bölgeler",
    addnewpatentinformation: "Yeni Patent Bilgileri Ekleyin",
    addpatent: "Patent Ekle",
    addnewvnjRvprecord: "Yeni VnjRvp Kaydı Ekle",
    decisiondate: "Karar Tarihi",
    decisionnumber: "Karar Numarası",
    entera2digitnumber: '2 Basamaklı bir sayı girin veya "Hayır"',
    editVnjRvprecord: "VnjRvp Kaydını Düzenle",
    validdate: "Geçerlilik Tarihi",
    eddresidencepermitinformation: "RVP ekle",
    selectdormitory: "Yurt Seç",
    editregistrationinformation: "Kayıt Bilgilerini Düzenle",
    addregistration: "Добавить регистрацию",
    editSNILSinformation: "SALYANGOZ Bilgilerini Düzenle",
    addnewSNILSinformation: "Yeni SNILS Bilgileri Ekleyin",
    pleaseentertheSNILSintheformat:
      "Lütfen snıls'i XXX-XXX-XXX XX biçiminde girin",
    addsnils: "Snıls Ekle",
    editvisainformation: "Vize Bilgilerini Düzenle",
    visaseria: "Vize Serisi",
    visano: "Vize No",
    addnewvisainformation: "Yeni Vize Bilgileri Ekle",
    addvisa: "Vize Ekle",
    thereisnoaddressavailable: "Mevcut adres yok",
    documentNumber: "Belge Numarası",
  },
  ru: {
    invoiceNumber: "Номер счета",
    createdAt: "Создано",
    dueDate: "Срок оплаты",
    subTotal: "Промежуточный итог",
    total: "Итого",
    invoiceStatus: "Статус счета",
    paymentStatus: "Статус оплаты",
    plan: "План",
    duration: "Продолжительность",
    numberofEmployee: "Количество сотрудников",
    employeeInPlain2do: "Сотрудник в Plain2do",
    editTimesheetDays: "Правка табеля",
    noKPP: "Нет КПП",
    companySphere: "Сфера деятельности компании",
    nameofCompany: "Название компании",
    enteraNumber: "Введите число",
    subtextTimesheet:
      "Пожалуйста, введите количество дней для добавления валидного табеля.",
    companyDetails: "Детали компании",
    enterInnofCompany: "Введите ИНН компании",
    editaddingTime: "Редактировать время добавления",
    addCompany: "Добавить компанию",
    addUser: "Добавить пользователя",
    systemAdmin: "Системный администратор",
    projectadmin: "Администратор проекта",
    budgetUser: "Пользователь бюджета",
    budgetManager: "Менеджер бюджета",
    hrUser: "Кадровый пользователь",
    hrManager: "Менеджер кадров",
    timekeeper: "Табельщик",
    emailOfUser: "Электронная почта пользователя",
    filterbyemail: "Фильтр по электронной почте...",
    allUserTypes: "Все типы пользователей",
    date: "Дата",
    subsPlan: "План подписки",
    accepted: "Принял",
    notaccepted: "Не принято",
    dateinvited: "Дата приглашения",
    userType: "Тип пользователя",
    invitedUser: "Приглашенные пользователи",
    logout: "Выход из системы",
    companyList: "Список компаний",
    invoices: "Счета-фактуры",
    profile: "Профиль",
    hi: "Привет",
    basis: "Основание",
    manager: "Менеджер",
    snils: "СНИЛС",
    noSnils: "Нет информации СНИЛС",
    edit: "Изменить",
    payrollHistory: "Зарплатная ведомость",
    editPersonalInfo: "Редактировать личную информацию",
    employeeActivation: "Активация сотрудников",
    cash: "Наличные",
    enterothers: "Введите здесь несколько примечаний... (необязательно)",
    enterInn: "Введите 12-значный номер",
    enterOthername: "Введите отчество",
    enterFirstname: "Введите имя",
    enterSurname: "Введите фамилию",
    enterNumber: "Введите табельный номер",
    finance: "Финансы",
    searchPlaceholder: "Поищи здесь...",
    dashboard: "Панель управления",
    generateReport: "Générer le rapport",
    projects: "Проекты",
    listProjects: "Проекты",
    budgets: "Бюджеты",
    ganttChart: "Диаграмма Ганта",
    laborDemand: "Список потребностей в рабочей силе",
    myTasks: "Мои задачи",
    kanbanBoard: "Доска Канбан",
    pendingTasks: "Задачи в ожидании",
    employee: "Сотрудник",
    employeeGeneralList: "Общий список сотрудников",
    employeeCard: "Карточка сотрудника",
    personalInformation: "Личная информация",
    employeeDocuments: "Документы сотрудников",
    certificates: "Сертификаты",
    costCentreTransferHistory: "Информация о зарплате и трансфере",
    timesheet: "Табель",
    payroll: "Зарплатная ведомость",
    dashboards: "Панели управления",
    statistics: "Статистика",
    analyticalReports: "Аналитические отчеты",
    projectDatatables: "Список проектов",
    downloadProjects: "Скачать",
    addProject: "Добавить проект",
    projectsPerPage: "Проектов на странице",
    projectName: "Название проекта",
    address: "Адрес",
    startDate: "Дата начала",
    endDate: "Дата окончания",
    action: "Действие",
    budgetData: "Данные бюджета",
    downloadBudgets: "Скачать бюджеты",
    addBudget: "Добавить бюджет",
    budgetsPerPage: "Бюджетов на странице",
    budgetVersion: "Версия бюджета",
    budgetCode: "Код бюджета",
    next: "Следующий",
    previous: "Предыдущий",
    salary: "Зарплата",
    primaveraCode: "Код Примавера",
    pagination: "Страница",
    paginationOf: "из",
    discipline: "Дисциплина",
    jobTitle: "Должность",
    employeeClass: "Класс сотрудника",
    employeeLevel: "Уровень сотрудника",
    documentType: "Тип документа",
    currency: "Валюта",
    unitOfMeasure: "Единица измерения",
    employeeQuantity: "Количество сотрудников",
    employeeSalary: "Заработная плата сотрудника",
    dayDifference: "Разница в днях",
    monthDifference: "Разница в месяцах",
    yearDifference: "Разница в годах",
    taxes: "Налоги",
    legalExpenses: "Юридические расходы",
    actions: "Действия",
    select_all: "Выбрать все",
    deselect_all: "Отменить выбор",
    all: "Все",
    active: "Активный",
    inactive: "Неактивный",
    personal_number: "Табельный номер",
    surname: "Фамилия",
    first_name: "Имя",
    position: "Должность",
    mobile_number: "Мобильный номер",
    current_status: "Статус",
    employeePerPage: "Строки на странице",
    addEmployee: "Добавить сотрудника",
    downloadEmployee: "Скачать данные сотрудника",
    employeesList: "Список сотрудников",
    employees: "Сотрудники",
    latest: "Последний",
    archive: "Архив",
    passportInformation: "Паспорт",
    noPassportInformation: "Нет информации о паспорте",
    passport: "Паспорт",
    countryCode: "Код страны",
    passportNumber: "Номер паспорта",
    givenNames: "Имя",
    nationality: "Национальность",
    birthDate: "Дата рождения",
    gender: "Пол",
    dateOfExpiry: "Дата истечения срока",
    dateOfIssue: "Дата выдачи",
    passportPhotoAlt: "Фото паспорта",
    noData: "Нет данных для отображения",
    visaInformation: "Виза",
    noVisaInformation: "Нет информации о визе",
    visa: "Виза",
    visaNumber: "Номер визы",
    nameSurname: "Фамилия Имя",
    issueDate: "Дата выдачи",
    fromDate: "Дата начала",
    validityDate: "Дата окончания",
    issuedBy: "Выдано",
    durationOfStay: "Срок пребывания",
    visaPhotoAlt: "Фото визы",
    patentInformation: "Патент",
    noPatentInformation: "Нет информации о патенте",
    patent: "Патент",
    registerSerialNumber: "Регистрационный серийный номер",
    patentPhotoAlt: "Фото патента",
    innInformation: "ИНН",
    noInnInformation: "Нет информации о ИНН",
    inn: "ИНН",
    employeeNumber: "Номер сотрудника",
    tinNumber: "ИНН номер",
    registrationInformation: "Регистрация",
    noRegistrationInformation: "Нет информации о регистрации",
    registration: "Регистрация",
    registrationAddress: "Адрес регистрации",
    registrationInDormitory: "Регистрация в общежитии",
    dormitory: "Общежитие",
    medicalInformation: "Медосмотр",
    noMedicalInformation: "Нет информации о медосмотре",
    medicalCheckup: "Медицинский осмотр",
    documentSerie: "Серия документа",
    migrationInformation: "Миграционная карта",
    noMigrationInformation: "Нет миграционной информации",
    migrationCard: "Миграционная Карта",
    patents: "Патенты",
    certificationsInformation: "Сертификаты",
    noCertificationsInformation: "Нет информации о сертификатах",
    certifications: "Сертификаты",
    certificationName: "Название сертификата",
    residencePermitInformation: "РВП",
    noResidencePermitInformation: "Нет информации",
    residencePermit: "РВП",
    fingerprintingInformation: "Дактилоскопия",
    noFingerprintingInformation: "Нет информации о дактилоскопии",
    fingerprinting: "Дактилоскопия",
    dateOfBirth: "Дата Рождения",
    status: "Статус",
    select: "выбрать",
    addnewproject: "Добавить новый проект",
    projectid: "ID проекта",
    enterenglishprojectname: "Введите название проекта на английском языке",
    projectcode: "Код проекта",
    enterprojectcode: "Введите код проекта",
    enterprojectid: "введите идентификатор проекта",
    enterrussianprojectname: "Введите название проекта на русском языке",
    enterturkishprojectname: "Введите название проекта на турецком языке",
    enterenglishaddress: "Введите адрес на английском языке",
    enterrussianaddress: "Введите российский адрес",
    enterturkishaddress: "Введите адрес на турецком языке",
    subjectofrf: "Субъект Российской Федерации",
    selectsubject: "Выбрать Субъект РФ",
    close: "Закрыть",
    savechanges: "Сохранить Изменения",
    project: "Проект",
    editproject: "Редактировать проект",
    selectdate: "Выбрать дату",
    selectproject: "Выбрать проект",
    addbudgetitem: "Добавить статью бюджета",
    submit: "Сохранить",
    back: "Назад",
    error: "Ошибка",
    httperrorstatus: "Ошибка HTTP! Статус: ",
    youdonothaveaccesstoaddbudgetdata:
      "У вас нет доступа к добавлению бюджетных данных.",
    success: "успех",
    therewasanissuefetchingtheprojectdata:
      "Возникла проблема с получением данных проекта: ",
    budgetitemaddedsuccessfully: "Бюджетная статья успешно добавлена",
    failedtodownloadprojectdata: "Не удалось загрузить данные проекта: ",
    therewasissuewithfetchoperation: "Возникла проблема с операцией выборки: ",
    failedtofetchsubjects: "Не удалось получить объекты",
    pleaseselectatleastoneitemtodownload:
      "Пожалуйста, выберите хотя бы один элемент для загрузки",
    failedtocreatenewproject: "Не удалось создать новый проект",
    projectaddedsuccessfully: "Проект успешно добавлен!",
    failedtoaddproject: "Не удалось добавить проект: ",
    loading: "Загрузка",
    therewasanissuefetchingthebudgetdetails:
      "Возникла проблема с получением подробной информации о бюджете: ",
    therewasanissuewiththesaveoperationpleasetryagainlater:
      "Возникла проблема с сохранением. Пожалуйста, повторите попытку позже.",
    newbudgetitemaddedsuccessfully: "Новая статья бюджета успешно добавлена",
    unexpectedresponseformat: "Неожиданный формат ответа",
    areyousure: "Вы уверены?",
    onceudeleteituwillnotbeabletorecoverthisbudgetitem:
      "После удаления вы не сможете восстановить эту статью бюджета!",
    warning: "предупреждение",
    proofyourbudgetitemhasbeendeleted: "Пуф! Ваша статья бюджета была удалена!",
    therewasanissuewiththedeleteoperationpleasetryagainlater:
      "Возникла проблема с удалением. Пожалуйста, повторите попытку позже",
    editmydata: "Измените мои данные.",
    selectdiscipline: "Выбрать дисциплину",
    enterbudgetcode: "Введите бюджетный код",
    enterprimaveracode: "Введите код Primavera",
    selectjobtitle: "Выбрать должность",
    budgetdetails: "Детали бюджета",
    loadingoptions: "Варианты загрузки",
    add: "Добавить",
    cancel: "Отменить",
    enterinput: "Введите вводные данные",
    oncedeletedyouwillnotbeabletorecoverthisproject:
      "После удаления вы не сможете восстановить этот проект!",
    failedtodownloadbudgetdata: "Не удалось загрузить бюджетные данные: ",
    failedtodeletetheproject: "Не удалось удалить проект",
    proofyourprojecthasbeendeleted: "Пуф! Ваш проект был удален!",
    totalcost: "Общая стоимость",
    author: "Автор",
    versiondate: "Дата версии",
    successfull: "Успешный",
    pending: "Ожидаемый",
    budgetitemeditedsuccessfully: "Бюджетная статья успешно отредактирована",
    projectupdatedsuccessfully: "Проект успешно обновлен!",
    failedtoupdateproject: "Не удалось обновить проект",
    failedtoaddtreasure: "Не удалось добавить сокровище:",
    anerrorocurred: "Произошла ошибка",
    treasureaddedsuccessfully: "Сокровище успешно добавлено!",
    financetreasure: "Финансовое сокровище",
    addtreasure: "Добавь сокровище",
    importtreasure: "Импорт Сокровищ",
    exporttreasure: "Экспорт Сокровищ",
    transaction: "операция",
    documentno: "Номер документа",
    type: "Тип",
    operationtype: "Тип операции",
    company: "Компания",
    counterparty: "Контрагент",
    expenseincome: "Расходы/Доходы",
    contractno: "Номер контракта",
    amount: "Сумма",
    addnewtreasures: "Добавь новое сокровище",
    selectoperationtype: "Выбрать тип операции",
    counter_party: "Контрагент",
    bankaccount: "Банковский счет",
    bankaccountcp: "Банковский счёт контрагента",
    expensetype: "Тип расхода",
    selectcurrency: "Выбрать валюту",
    costcenter: "Центр затрат",
    comment: "Комментарий",
    paymentdetails: "Платежные реквизиты",
    selectcounterparty: "Выбрать контрагента",
    bank: "Банк",
    vat: "НДС",
    treasure: "Сокровище",
    vatamount: "Сумма НДС",
    treasury: "Казначейство",
    bankandcash: "Банк и наличные деньги",
    financetreasury: "Казначейство",
    addtreasurybank: "Добавить операцию",
    importtreasury: "Скачать",
    exporttreasury: "Выгрузить",
    our_company: "Наша компания",
    addtreasurycash: "Добавить Операцию - наличные",
    addnewtreasury: "Добавить Операцию - Банк",
    kbbo: "КББО",
    code: "Код",
    employeeaddedsuccessfully: "Сотрудник успешно добавлен!",
    dateofemployment: "Дата приема на работу",
    dateofdismissial: "Дата увольнения",
    surname: "Фамилия",
    othername: "Отчество",
    selectgender: "Выбрать пол",
    male: "мужчина",
    female: "женщина",
    tinumber: "Номер ИНН",
    snilsnumber: "Номер СНИЛС",
    phonenumber: "Номер телефона",
    others: "Другие",
    photo: "Фотография",
    selectposition: "Выбрать должность",
    citizenship: "Гражданство",
    currentdocumentcategorydata: "Данные о текущей категории документа",
    selectdocument: "Выбрать документ",
    selectcountry: "Выбрать страну",
    failedtodownloademployeedata: "Не удалось загрузить данные о сотрудниках:",
    oncedeletedyouwillnotbeabletorecoverthisemployee:
      "После удаления вы не сможете восстановить этого сотрудника!",
    failedtodeletetheemployee: "Не удалось удалить сотрудника",
    proofyouremployeehasbeendeleted: "Пуф! Ваш сотрудник был удален!",
    failedtodeleteemployee: "Не удалось удалить сотрудника",
    excelfileuploadedsuccessfully: "Файл Excel успешно загружен!",
    failedtouploadexcelfile: "Не удалось загрузить файл Excel",
    upload: "Загрузить",
    uploading: "Загрузка",
    enteryourpassportnumber: "Введите номер своего паспорта",
    issuedby: "Выдан кем",
    issuedate: "Дата выдачи",
    validitydate: "Срок действия",
    personalidentificationnumber: "Личный идентификационный номер",
    enterpersonalidentificationnumber: "Введите личный идентификационный номер",
    nochanges: "Никаких изменений",
    therearenochangestosave:
      "Нет никаких изменений, которые нужно было бы сохранить.",
    personalinformationupdatedsuccessfully:
      "Личная информация успешно обновлена!",
    therewasanissueupdatingtheinformation:
      "Возникла проблема с обновлением информации: ",
    save: "Сохранить",
    employmentdocuments: "Трудовые документы",
    salarytransferinfo: "Зарплаты и трансферы",
    selectmanager: "Выберите менеджера",
    addpayrollhistory: "Добавить заработную плату",
    selectdocumenttype: "Выберите тип документа",
    selectunitofmeasure: "Выберите единицу измерения",
    startofworkdate: "Дата начала работы",
    endofworkdate: "Дата окончания работы",
    selectworkschedule: "Выберите График работы",
    jobtitletype: "Должность",
    currencytype: "Валюта",
    selectpaymenttype: "Выберите Тип платежа",
    selectcostcentre: "Выберите центр затрат",
    editsalarytransferhistory: "Редактировать историю зарплат и переводов",
    oncedeletedyouwillnotbeabletorecoverthisrecord:
      "После удаления вы не сможете восстановить эту запись!",
    failedtodeleterecordserverrespondedwithstatus:
      "Не удалось удалить запись: Сервер ответил сообщением о состоянии",
    failedtodeleterecord: "Не удалось удалить запись:",
    deleted: "Удалено!",
    thepayrollrecordhasbeendeleted:
      "Запись о начислении заработной платы была удалена",
    editabledatasuccessfullychanged: "Редактируемые данные успешно изменены!",
    payrollhistoryaddedsuccessfully:
      "История начисления заработной платы успешно добавлена!",
    description: "Описание",
    saldo: "Салдо",
    payrollhistroyuser: "Начислении заработной платы сотрудника",
    dissmisaldate: "Дата увольнения",
    addnewrlcertification: "Добавить новую сертификацию RL",
    placeofissue: "Место выдачи",
    editrlcertification: "Редактировать сертификацию RL",
    adddaktilrecord: "Добавить запись Дактилоскопия",
    issueby: "Выпуск По",
    addrlcertificate: "Добавить сертификат RL",
    editdaktilinformation: "Редактировать информацию о Дактилоскопии",
    addfingerprintinformation: "Добавить информацию о Дактилоскопии",
    editinninformation: "Редактировать информацию о гостинице",
    documentNoSNILSFormat: "Номер документа (формат СНИЛС)",
    format: "Формат",
    addnewinninformation: "Добавить новую информацию о гостинице",
    documentNoINNFormat: "Номер документа (формат ИНН)",
    addinn: "Добавить ИНН",
    addnewregistrationinformation: "Добавить новую регистрационную информацию",
    editmedicalinformation: "Редактировать медицинскую информацию",
    documentseria: "Серия документов",
    addmedicalinformation: "Добавить медицинскую информацию",
    addmigrationinformation: "Добавить информацию о миграции",
    todate: "До даты",
    visitpurpose: "Цель визита",
    bordercheckpoint: "Пограничный пункт пропуска",
    editmigrationinformation: "Редактировать информацию о миграции",
    editpassportinformation: "Редактировать паспортные данные",
    passportserie: "Серия паспортов",
    countrypersnumber: "Количество человек в стране",
    letter: "Письмо",
    latin: "Латинский",
    cyrillic: "Кириллица",
    addnewpassportinformation: "Добавить новые паспортные данные",
    selectnationality: "Выберите национальность",
    selectissuedby: "Выберите Выданный",
    addpassport: "Выберите Выданный",
    editpatentinformation: "Редактировать информацию о пациенте",
    specialmarks: "Специальные знаки",
    validterritories: "Действующие территории",
    addnewpatentinformation: "Добавление новой патентной информации",
    addpatent: "Добавить патент",
    addnewvnjRvprecord: "Добавить новую запись Rvp",
    decisiondate: "Дата принятия решения",
    decisionnumber: "Номер решения",
    entera2digitnumber: 'Введите 2-значный номер или "Нет".',
    editVnjRvprecord: "Редактировать запись VnjRvp",
    validdate: "Действительная дата",
    eddresidencepermitinformation: "Добавить РВП",
    selectdormitory: "Выберите общежитие",
    editregistrationinformation: "Редактировать регистрационную информацию",
    addregistration: "Добавить регистрацию",
    editSNILSinformation: "Редактировать информацию о СНИЛС",
    addnewSNILSinformation: "Добавить новую информацию о СНИЛС",
    pleaseentertheSNILSintheformat:
      "Пожалуйста, введите СНИЛС в формате XXX-XXX-XXX XX",
    addsnils: "Добавить Снилс",
    editvisainformation: "Редактировать визовую информацию",
    visaseria: "Серия виз",
    visano: "Номер визы",
    addnewvisainformation: "Добавить новую визовую информацию",
    addvisa: "Добавить визу",
    thereisnoaddressavailable: "Доступного адреса нет",
    documentNumber: "Номер документа",
  },
};

export default translations;
