import { lazy, Suspense, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Index from "./jsx";
import {
  Route,
  Switch,
  withRouter,
  useLocation,
  useHistory,
} from "react-router-dom";
import { checkAutoLogin } from "./services/AuthService";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import { DemoRegistr } from "./jsx/pages/Registration-demo/Registration-demo.js";
import InvitedUser from "./jsx/pages/InvitedUser.jsx"; // Import InvitedUser directly
import ResetPassword from "./jsx/pages/forgot-password/ForgotPassword.js";
import CodeVerification from "./jsx/pages/code-verification/CodeVerification.js";
import NewPassword from "./jsx/pages/newpassword/NewPassword";
const SignUp = lazy(() => import("./jsx/pages/Registration"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login.js")), 500);
  });
});

function App(props) {
  const tokenLocal = localStorage.getItem("userDetails");
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const publicRoutes = [
    "/login",
    "/page-register",
    "/page-forgot-password",
    "/registration-demo",
    "/reset-password",
    "/code-verification",
  ];
  // Extract query parameters
  const searchParams = new URLSearchParams(location.search);
  const u = searchParams.get("u");
  const token = searchParams.get("token");
  // Check auto-login for non-public routes
  useEffect(() => {
    if (
      !publicRoutes.includes(location.pathname) &&
      !location.pathname.startsWith("/invited-user")
    ) {
      checkAutoLogin(dispatch, props.history);
    }
  }, [dispatch, props.history, location.pathname]);

  // Redirect to login if parameters are missing
  useEffect(() => {
    if (location.pathname === "/invited-user" && (!u || !token)) {
      history.push("/login");
    }
  }, [u, token, location.pathname, history]);
  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ResetPassword} />
      <Route path="/registration-demo" component={DemoRegistr} />
      <Route path="/reset-password" component={NewPassword} />
      <Route path="/code-verification" component={CodeVerification} />
      <Route path="/invited-user">
        <InvitedUser u={u} token={token} />
      </Route>
    </Switch>
  );

  if (tokenLocal) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  }
}

export default withRouter(connect()(App));
