import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import swal from "sweetalert";
import { useLanguage } from "../../../../context/LanguageContext";
import translations from "../../../../translation/translation";
import { Button, Spinner } from "react-bootstrap";

const PayrollHistoryUser = () => {
  const { id } = useParams();
  const parser = JSON.parse(localStorage.getItem("userDetails"));
  const token = parser.access;
  const history = useHistory();
  const { language } = useLanguage();
  const t = translations[language];
  const m = translations.payroll[language];
  const urlLink = process.env.REACT_APP_API_URL;
  const [historyUser, setHistoryUser] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!token) {
      console.error("No access token available.");
      history.push("/login");
      return;
    }

    setLoading(true);

    const fetchData = async () => {
      const url = `${urlLink}/employee-history-payroll/${id}/`;

      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      try {
        const response = await fetch(url, requestOptions);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();

        setHistoryUser(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        swal(
          "Error",
          "There was an issue with the fetch operation: " + error.message,
          "error"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token, history, id, urlLink]);

  const formatAmount = (amount) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
      .format(amount)
      .replace(/,/g, " "); // Replace commas with spaces
  };

  const downloadProjects = () => {
    if (!token) {
      console.error("No access token available.");
      return;
    }

    let url = `${urlLink}/employee-history-payroll/${id}/?excel=True&language=${language.toUpperCase()}`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    fetch(url, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${t.httperrorstatus}${response.status}`);
        }
        // Extract filename from headers
        const disposition = response.headers.get("Content-Disposition");
        let filename = "payroll-history.xls"; // Default filename

        if (disposition && disposition.includes("filename=")) {
          // Extracting the filename from the Content-Disposition header
          const matches = disposition.match(/filename="([^"]+)"/);
          if (matches && matches[1]) {
            filename = matches[1];
          }
        }

        return response.blob().then((blob) => ({ blob, filename }));
      })
      .then(({ blob, filename }) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename; // Use the extracted filename from the response
        document.body.appendChild(a);
        a.click();
        a.remove(); // Cleanup
        window.URL.revokeObjectURL(url); // Free up memory
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
        swal(
          t.error.charAt(0).toUpperCase() + t.error.slice(1),
          t.failedtodownloadprojectdata + error.message,
          "error"
        );
      });
  };

  const renderTableRows = () => {
    return historyUser.map((content) => (
      <tr key={content.id}>
        <td style={{ whiteSpace: "nowrap" }}>
          {content?.payroll_date || "No date"}
        </td>
        <td>{content[`period${language.toUpperCase()}`] || "No Period"}</td>
        <td>
          {content[`description${language.toUpperCase()}`] || "No Finance"}
        </td>
        <td style={{ whiteSpace: "nowrap" }}>
          {formatAmount(content?.payroll_amount) || "0"}
        </td>
        <td style={{ whiteSpace: "nowrap" }}>
          {formatAmount(content?.finance_amount) || "0"}
        </td>
        <td>{content[`currency${language.toUpperCase()}`] || "No Currency"}</td>
        <td style={{ whiteSpace: "nowrap" }}>
          {formatAmount(content?.cumulative_saldo) || "No Saldo"}
        </td>
      </tr>
    ));
  };

  return (
    <div>
      <div className="card">
        <div
          className="card-header"
          style={{ padding: "20px", paddingLeft: "30px" }}
        >
          <h4 className="card-title">{t.payrollhistroyuser}</h4>

          <Button variant="primary" onClick={downloadProjects}>
            {m.download}
          </Button>
        </div>
        <div className="card-body" style={{ padding: "20px" }}>
          <div className="table-responsive">
            <table className="table table-responsive-md card-table transactions-table">
              <thead>
                <tr>
                  <th>{t.date}</th>
                  <th>{m.period}</th>
                  <th>{t.description}</th>
                  <th>{t.amount}</th>
                  <th>{t.amount}</th>
                  <th>{t.saldo}</th>
                </tr>
              </thead>
              <tbody>{renderTableRows()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollHistoryUser;
